import {
  makeStyles,
  createStyles,
  Theme,
  Grid,
  Typography,
  Tooltip,
  CircularProgress,
  Avatar,
} from "@material-ui/core";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { getAllSpeciality } from "../../api/AppointmentManagement/Appointment";
import { getAllDoctors } from "../../api/InstantVideo/Instant";
import CloseIcon from "../../assets/images/consultation/CloseIcon";
import CommonDropDown from "../common/CommonDropDown";
import CommonMenuItem from "../common/CommonDropDown/CommonMenuItem";
import CustomDialog from "../common/CustomDialog";
import CustomSubmitButton from "../common/CustomSubmitButton";
import AddUser from "../../assets/images/wpf_add-user.png";
import { UpdateConnectingInfo } from "../../store/actions";
import {
  listValue,
  AddDoctorlist,
} from "../../pages/consultation/DoctorRejectList";
import { useLocation } from "react-router-dom";
import { Ilocation } from "../../models/consultation";
import { sendMsg } from "../Websocket/InstantChatIO";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "grid",
      gridTemplateRows: "1fr auto",
    },
    AddDoctor: {
      height: "365px",
      "&.MuiDialog-paper": {
        width: "500px !important",
      },
      "& .MuiDialogTitle-root": {
        color: "#32529D !important",
      },
      "& .MuiDialogContent-root": {
        padding: "8px 0px !important",
        overflow: "hidden !important",
      },
    },
    buttonWrapper: {
      background: "transparent",
      color: "#34519C",
      border: "1px solid #B5C3E8",
      borderRadius: "21.5px",
      padding:"4px 14px",
      "& .Mui-disabled": {
        background:'lightgrey'
      },
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    DoctorGrid: {
      display: "flex",
      flexDirection: "row",
    },
    Doctorimg: {
      border: "1px solid grey",
      height: "46px",
      width: "46px",
      borderRadius: "2rem",
    },
    DoctorOnlinestatus: {
      height: "10px",
      width: "10px",
      background: "green",
      borderRadius: "5px",
      position: "relative",
      top: "-14px",
      left: "35px",
    },
    DoctorOfflinestatus: {
      height: "10px",
      width: "10px",
      background: "red",
      borderRadius: "5px",
      position: "relative",
      top: "-14px",
      left: "35px",
    },
    DoctorDetail: {
      display: "flex",
      flexDirection: "column",
      marginLeft: "10px",
      justifyContent: "center",
      alignItems: "flex-start",
      paddingBottom: "1rem",
    },
    DoctorName: {
      fontWeight: 500,
      fontSize: "14px !important",
      lineHeight: "16px",
      marginBottom:"2px",
      color: "#151515",
      whiteSpace: "nowrap",
      width: "15rem",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    avatarIcons: {
      width: "40px",
      height: "40px",
      borderRadius: "50%",
      fontSize: "20px",
      color: "#335E7D",
      backgroundColor: "#CDE3F3",
    },
    DoctorSpeciality: {
      fontWeight: 500,
      fontSize: "12px !important",
      lineHeight: "16px",
      color: "#717171",
      width: "15rem !important",
      wordWrap: "break-word",
    },
    Title: {
      "& .MuiDialogTitle-root": {
        color: "#000000 !important",
        backgroundColor: "#EDEFF3 !important",
      },
    },
    DoctorBody: {
      overflow: "auto",
      height: "285px",
      padding: "0px 20px",
    },
    loadDiv:{
      height: "390px !important",
    },
    progressBar: {
      height:'30px !important',
      width:'30px !important',
      AlignItems: "center",
      display: "flex",
      left: "50%",
      margin: "0px auto",
    },
    topProgressBar: {
      height: "30px !important",
      width: "30px !important",
      AlignItems: "center",
      display: "flex",
      left: "50%",
      margin: "0px auto",
    },
    moreLessBtn: {
      fontWeight: 500,
      fontSize: '11px !important',
      lineHeight: '14px',
      color: '#34519C',
      cursor: "pointer",
    }
  })
);

const OnlineDoctorlist = ({ open, closeDialog }: any) => {
  const dispatch = useDispatch();
  const [openDialog, setOpen] = useState<boolean>(false);
  const [loading, setloading] = useState<boolean>(false);
  const classes = useStyles();
  const [doctorlist, setdoctorlist] = useState([]);
  const onlinelist = useSelector((state: any) => state?.OnlineDoctors);
  const [showlist, setshowlist] = useState<any>([]);
  const [PageNumber, setPageNumber] = useState(1);
  const [Refetch, setRefetch] = useState(false);
  const { instantdata, bookedId, connecting, callID } = useSelector(
    (state: any) => state?.instantVideo
  );
  const [moreIcon, setmoreIcon] = useState<any>({});

  const { control, getValues, watch } = useForm({
    mode: "all",
  });
  watch("Specialities");
  const { Specialities } = getValues();
  const { data: specialitiesList = [] } = useQuery([1000, 1], getAllSpeciality);
  const location = useLocation<Ilocation>()
  const searchVal = location.search.split('?')[1];
  let isCallBack = searchVal?.split('/').length > 1

  useEffect(() => {
    setOpen(open);
    open === true && setPageNumber(1);
  }, [open]);
  useEffect(() => {
    if (openDialog) {
      setloading(true);

      (async () => {
        const book_ID = isCallBack ? callID?.split('/')[0] : bookedId
        const payload = {
          pageSize: 10,
          pageNumber: PageNumber,
          consultType: "INSTANT",
          bookedSlotId:book_ID
        };
        if (Specialities) {
          payload["speciality"] = Specialities;
        }
        try {
          const res = await getAllDoctors(payload);
          if (res && res?.data) {
            setdoctorlist(res?.data);
            setloading(false);
          } else {
            setloading(false);
            setdoctorlist([]);
            setshowlist([]);
          }
        } catch (e) {
          setloading(false);
          setdoctorlist([]);
          setshowlist([]);
        }
      })();
    }
  }, [PageNumber, Refetch, Specialities, openDialog]);

  useEffect(() => {
    if (doctorlist.length > 0) {
        var uniq = {}; 
        let combinedValue =[...showlist, ...doctorlist]
        var filteredValue = combinedValue.filter(obj => !uniq[obj.facilityPractitioner] && (uniq[obj.facilityPractitioner] = true));
        PageNumber === 1? setshowlist([...doctorlist]): setshowlist(filteredValue);
    }
  }, [doctorlist]);

  useEffect(() => {
    if (
      AddDoctorlist["connecting"] &&
      connecting?.split &&
      !connecting?.show &&
      !instantdata?.doctorConnected
    ) {
      listValue[AddDoctorlist["connecting"]] = AddDoctorlist["connecting"];
      delete AddDoctorlist["connecting"];
    }
  }, [connecting?.show, instantdata?.doctorConnected]);

  const scroll = (): boolean => {
    const element = document.getElementById("DoctorBody");
    if (element !== null) {
      if (element.scrollHeight === element.clientHeight) {
        return true;
      } else {
        return false;
      }
    } else return true;
  };

  const renderMoreLess = (key, value) => {
    const specialitylist = value?.slice(0, 2);
    const primaryName = specialitylist?.map((x: any) => x.providerSpeciality);
    const secondaryName = value?.map((x: any) => x.providerSpeciality);
    return (
      <>
        <Typography className={classes.DoctorSpeciality}>
          {" "}
          {!moreIcon[key] ? primaryName?.join(",") : secondaryName.join(",")}
        </Typography>
        {value?.length > 2 && (
          <Typography
            className={classes.moreLessBtn}
            onClick={() => {
              if (!moreIcon[key]) {
                const obj = {};
                obj[key] = true;
                setmoreIcon({ ...moreIcon, ...obj });
              } else {
                const obj = JSON.parse(JSON.stringify(moreIcon));
                delete obj[key];
                setmoreIcon(obj);
              }
            }}
          >
            {!moreIcon[key] ? "...More" : "...Less"}
          </Typography>
        )}
      </>
    );
  };
  const renderSpeciality = (v: any, index) => {
    return (
      <CommonMenuItem value={v?._id}>{v?.providerSpeciality}</CommonMenuItem>
    );
  };
  const renderContainer = (doctor) => {
    const fullName = doctor?.name?.first + " " + doctor?.name?.last;
    //AS PER TASK-30586 THIS LINE IS COMMENTED
    // if (!listValue[doctor?.practitioner])
      return (
        <Grid container>
          <Grid item xs={7} className={classes.DoctorGrid}>
            <div>
              {doctor?.profilePic ? (
                <div
                  className={classes.Doctorimg}
                  style={{
                    backgroundImage: `url(${doctor?.profilePic})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></div>
              ) : (
                <Avatar className={classes.avatarIcons}>
                  {doctor?.name?.first?.toUpperCase()?.charAt(0) +
                    doctor?.name?.last?.toUpperCase()?.charAt(0)}
                </Avatar>
              )}
              <div
                className={
                  onlinelist[doctor?.practitioner]?.status &&
                 ( onlinelist[doctor?.practitioner]?.status.includes("offline") || onlinelist[doctor?.practitioner]?.status.includes("busy"))
                    ? classes.DoctorOfflinestatus
                    : classes.DoctorOnlinestatus
                }
              />
            </div>          
            <div className={classes.DoctorDetail}>
              {fullName.length > 20 ? (
                <Tooltip
                  title={`Dr. ${doctor?.name?.first} ${doctor?.name?.last}`}
                >
                  <Typography
                    className={classes.DoctorName}
                  >{`Dr. ${doctor?.name?.first} ${doctor?.name?.last}`}</Typography>
                </Tooltip>
              ) : (
                <Typography
                  className={classes.DoctorName}
                >{`Dr. ${doctor?.name?.first} ${doctor?.name?.last}`}</Typography>
              )}
              {/* <span style={{ height: "10px", width: "100%" }} /> */}
              <div>
                {renderMoreLess(doctor?.practitioner, doctor?.specialities)}
              </div>
            </div>
          </Grid>
          <Grid item xs={5} className={classes.buttonContainer}>
            <CustomSubmitButton
              className={classes.buttonWrapper}
              disabled={onlinelist[doctor?.practitioner]?.status.includes("offline") || onlinelist[doctor?.practitioner]?.status.includes("busy")}
              onClick={() => {
                const call_Id = isCallBack ? callID?.split('/')[1] : instantdata?.callId;
                const book_ID = isCallBack ? callID?.split('/')[0] : bookedId
                sendMsg('addDoctor',{
                  doctorId: doctor?.practitioner,
                  callId: call_Id,
                  bookedId: book_ID
                });
                closeDialog();
                AddDoctorlist["connecting"] = doctor?.practitioner;
                dispatch(
                  UpdateConnectingInfo({
                    ...connecting,
                    split: true,
                    show: true,
                    name: `${doctor?.name?.first} ${doctor?.name?.last}`,
                  })
                );
              }}
            >
              Add to Video Call
            </CustomSubmitButton>
          </Grid>
        </Grid>
      );
    // else <></>;
  };
  return (
    <CustomDialog
      size={"md"}
      open={openDialog}
      title={"Doctors"}
      classes={{ paper: loading && !scroll() ? clsx(classes.AddDoctor, classes.Title,classes.loadDiv) : clsx(classes.AddDoctor, classes.Title) }}
      onClose={closeDialog}
      children={
        <div>
          <div
            id={"DoctorBody"}
            className={classes.DoctorBody}
            onScroll={(event) => {
              const listboxNode = event.currentTarget;
              if (
                listboxNode.scrollHeight -
                  listboxNode.scrollTop -
                  listboxNode.clientHeight <
                1
              ) {
                setPageNumber(PageNumber + 1);
              }
            }}
          >
            <div style={{ width: "50%", marginBottom: "4%" }}>
              <Controller
                name="Specialities"
                control={control}
                render={(controlProps) => {
                  return (
                    <CommonDropDown
                      multiple={false}
                      onChange={(e: any) => {
                        controlProps.onChange(e.target.value);
                        setPageNumber(1);
                        e.target.value && setshowlist([]);
                      }}
                      value={controlProps.value || "All"}
                      placeholder={"All specialty"}
                    >
                      {[
                        ...[
                          <CommonMenuItem value={""}>
                            All specilaity
                          </CommonMenuItem>,
                        ],
                        ...specialitiesList.map(renderSpeciality),
                      ]}
                    </CommonDropDown>
                  );
                }}
              />
            </div>
            {showlist.map(renderContainer)}
            
            {showlist.length === 0 &&
              !loading && (
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  direction="column"
                >
                  <Grid justifyContent="center" alignItems="center">
                    <div
                      style={{
                        height: "60px",
                        width: "60px",
                        borderRadius: "2rem",
                        background: "#F7F7F7",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      <img src={AddUser} />
                    </div>
                  </Grid>
                  <Grid
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      color: "#747474",
                      padding: "5%",
                      textAlign: "center",
                    }}
                  >
                    No doctors available to connect right now. You can refresh
                    or try again later.
                  </Grid>
                  <Grid justifyContent="center" alignItems="center">
                    <CustomSubmitButton
                      className={""}
                      onClick={() => {
                        setRefetch(!Refetch);
                      }}
                    >
                      Refresh
                    </CustomSubmitButton>
                  </Grid>
                </Grid>
              )}
            {loading && scroll() && (
              <CircularProgress className={classes.progressBar} />
            )}
          </div>
          <div style={ loading && !scroll() ? {height:'50px'} :{display:"none"}}>
             {loading && !scroll() && (<CircularProgress className={classes.progressBar} />)}
          </div>
        </div>
      }
      autoWidth={undefined}
      enableFooter={undefined}
      CloseIcon={<CloseIcon />}
    />
  );
};

export default OnlineDoctorlist;