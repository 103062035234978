import { useHistory } from 'react-router-dom';
import {
  Tab,
  Tabs,
  AppBar, IconButton, Grid
} from "@material-ui/core";
import styles from './styles';
import moment from 'moment';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Table from '../../components/Table';
import CustomIconButton from '../../components/common/CustomIconButton';
import Complete from '../../assets/images/icons/Group.svg';
import videoIcon from '../../assets/images/icons/ph_video-camera-fill.svg';
import { useEffect, useState, useRef } from 'react';
import { useSelector, RootStateOrAny, useDispatch } from 'react-redux';
import { USER_ROLES } from '../../utils/constants';
import { UpdateCallbackInfo } from '../../store/actions/instantVideo';
import { Irequest } from '../../models/common';
import Notify from '../../components/common/CommonToast';


const TYPES =
{
  VIRTUAL_CONSULTATION : "Virtual Consult",
  PHYSICAL_CONSULTATION : "Physical Consult",
  INSTANT : "Instant Call",
  CHAT :'Instant Chat'
}
const ListView = ({setOpenDetilsPopup, userRole_Static, activeTab, isLoading, setActiveTab, tablePage, type,tabledata,totalRecords,rowsPerPage,tableLength,recordCount,setSelData,handlePageChange} : any) => {
  const classes = styles() 
  const history = useHistory();
  const dispatch = useDispatch();
  let worker = useRef(new Worker("/joinCallWorker.js")).current;
  const [aptData, setAptData] = useState<any>([]);
  const Enable = useRef<boolean>(false);
  const {userRole,dbData} = useSelector((state: RootStateOrAny) => state.auth);
  const [request, setRequest] = useState<Irequest>({
    title:"",
    message: "",
    loading: false,
    severity: "",
  });

  useEffect(() => {
    if (worker && !isLoading && tabledata?.data && userRole === USER_ROLES.doctor) {
      Enable.current = true ;
      worker.postMessage({key1 : tabledata.data, key2 : dbData?.SLOT_INTERVAL_MINUTES??10});
      worker.onmessage = (res) => {
        const _ndata = tabledata?.data.map((item: any) => {
          res.data[0]._id === item._id
            ? (item.isCallEnable = res.data[1])
            : (item.isCallable = false);
          return item;
        });
        setAptData(_ndata)
        Enable.current = false ;
      };
    }
    setAptData(tabledata?.data)
  }, [tabledata]);

  useEffect(() => {
    return () => {
      worker.terminate();
    };
  }, []);

  const tableHeaderUpcoming_Admin = [
    {
      id: "user",
      numeric: false,
      disablePadding: false,
      label: "Participant Name",
      render:(text) => (text?.name?.first +" "+ text?.name?.last)
    },
    {
      id: "practitioner",
      numeric: false,
      disablePadding: false,
      label: "Doctor/Receptionist",
      width:"25%",
      render:(text) => ((text?.name?.prefix ?? "")+""+text?.name?.first +" "+ text?.name?.last)
    },
    {
      id: "consultationType",
      numeric: false,
      disablePadding: false,
      label: "Consultation Type",
      render: (text:any, row:any) => {
        return(
          <>
          {TYPES[row?.consultationType]}
          </>
        )
      },
    },
    {
      id: "date",
      numeric: false,
      disablePadding: false,
      label: "Date",
      render: (text: any) => moment(text).format("DD/MM/YYYY")
    },
    {
      id: "time",
      numeric: false,
      disablePadding: false,
      label: "Time",
      render: (text: any, row:any) => { 
      let hour = Math.floor(row?.slotStartTime/3600000);
      let minute = (Math.floor(row?.slotStartTime/60000) % 60)
      return moment(`${hour}:${minute}`, "HH:mm").format("hh:mm A")      
      }
    },
    {
      id: "",
      numeric: false,
      disablePadding: false,
      label: "",
      render: (text:string, row:any) => {
          return (
            <IconButton onClick={handleRowOnclick.bind(null, row)} style={{height:"15px", width: "15px"}}>
                <ArrowForwardIosIcon style={{fontSize: 18}} />
            </IconButton>
          )
      }
    }
    
  ]

  const tableHeaderUpcoming_Operator = [
    {
      id: "user",
      numeric: false,
      disablePadding: false,
      label: "Patient Name",
      render:(text,row,index) => (text.name?.first + text.name?.last)
    },
    {
      id: "practitioner",
      numeric: false,
      disablePadding: false,
      label: "Consulting With",
      render:(text,row,index) => (text.name?.first + text.name?.last)
    },
    {
      id: "consultationType",
      numeric: false,
      disablePadding: false,
      label: "Consultation Type",
      render: (text:any, row:any) => {
        return(
          <>
          {TYPES[row?.consultationType]}
          </>
        )
      },
  },
    {
      id: "date",
      numeric: false,
      disablePadding: false,
      label: "Date",
      render: (text: any) => moment(text).format("DD/MM/YYYY")
    },
    {
      id: "time",
      numeric: false,
      disablePadding: false,
      label: "Time",
      render: (text: any, row:any) => { 
      let hour = Math.floor(row?.slotStartTime/3600000);
      let minute = (Math.floor(row?.slotStartTime/60000) % 60)
      return moment(`${hour}:${minute}`, "HH:mm").format("hh:mm a")      
      }    
    },
    {
      id: "",
      numeric: false,
      disablePadding: false,
      label: "",
      render: (text:string, row:any) => {
          return (
            <IconButton onClick={handleRowOnclick.bind(null, row)}  style={{height:"15px", width: "15px"}}>
                <ArrowForwardIosIcon style={{fontSize: 18}} />
            </IconButton>
          )
      }
    }
    
  ]

  const tableHeaderUpcoming_Doctor = [
    {
      id: "user",
      numeric: false,
      disablePadding: false,
      label: "Participant Name",
      render:(text,row,index) => (text?.name?.first +" "+ text?.name?.last)
    },
    {
      id: "consultationType",
      numeric: false,
      disablePadding: false,
      label: "Consultation Type",
      render: (text:any, row:any) => {
        return(
          <>
          {TYPES[row?.consultationType]}
          </>
        )
      },
    },
    {
      id: "date",
      numeric: false,
      disablePadding: false,
      label: "Date",
      render: (text: any) => moment(text).format("DD/MM/YYYY")
    },
    {
      id: "Time",
      numeric: false,
      disablePadding: false,
      label: "Time",
      render: (text: any, row:any) => { 
        let hour = Math.floor(row?.slotStartTime/3600000);
        let minute = (Math.floor(row?.slotStartTime/60000) % 60);
        return moment(`${hour}:${minute}`, "HH:mm").format("hh:mm a");
        }    },
    {
      id: "slotStartTime",
      numeric: true,
      disablePadding: false,
      label: "",
      width:"5%",
      render: (text:any, row:any, index:any, data:any) => {
        var current = new Date()
        var currentTime =`${current.getHours()}:${current.getMinutes()}`
        const splitValue= currentTime.split(":")
        const splitted=(+splitValue[0] * (60000 * 60)) + (+splitValue[1] * 60000)
        return (
          <>
             {(splitted/1000 - text/1000 >= -300 && 
              splitted/1000 - text/1000  <= 900 && 
              moment(row?.date).format("DD/MM/YYYY") === moment().format("DD/MM/YYYY") && row?.isCallEnable && !Enable.current) &&
              <CustomIconButton
                icon={row.consultationType === "VIRTUAL_CONSULTATION" ? <img src={videoIcon} style={{paddingRight: '4px'}}/> : <img src={Complete} style={{paddingRight: '4px'}}/>}
                label={row.consultationType === "VIRTUAL_CONSULTATION" ? "Join" : "Complete"}
                classes={classes.submitButton}
                variant="contained"
                onClick={(e:any)=> {
                  setRequest({ message: "", loading: true, severity: "" });
                  const currentRejoin = (!document.getElementById("rejoinButton") || document.getElementById("rejoinButton")?.getAttribute("bookedslotid") === row?._id )
                  if(currentRejoin)
                  {
                  dispatch(UpdateCallbackInfo({}));
                  if(row.consultationType === "VIRTUAL_CONSULTATION"){
                    history.push({
                      pathname: `/Appointments/room`,
                      search: `${row?._id}`,
                      state:{
                        row:{row}
                      }
                    })
                  } else {
                    history.push({pathname:"/Appointments/consultation", state:{
                      row:{row}
                    }})
                  }
                  e.stopPropagation()
                }else{
                  setTimeout(() => {
                    setRequest({
                      message: "Please end the ongoing call/chat to join a new consultation.",
                      loading: false,
                      severity: "error",
                    });
                  },10)
                }
              }
                }
                size="small"
            />
            } 
          </>
        )
      }
    },
    {
      id: "",
      numeric: false,
      disablePadding: false,
      label: "",
      render: (text:string, row:any) => {
          return (
            <IconButton onClick={handleRowOnclick.bind(null, row)} style={{height:"15px", width: "15px"}}>
                <ArrowForwardIosIcon style={{fontSize: 18}} />
            </IconButton>
          )
      }
    } 
  ]

  const getTableHeaderUpcoming = () => {
    if(userRole_Static === "Admin" || userRole_Static === "Receptionist"
        || userRole_Static === "Operatornal_Receptionist" || userRole_Static === "Facility_Receptionist"){
      return tableHeaderUpcoming_Admin
    } else if (userRole_Static === "Operator" || type === "FollowupRequest"){
      return tableHeaderUpcoming_Operator
    } else if (userRole_Static === "Doctor"){
      return tableHeaderUpcoming_Doctor
    }
  }

  const tableHeaderHistory = [
    {
      id: "user",
      numeric: false,
      disablePadding: false,
      label: "Participant Name",
      render:(text,row,index) => (text.name?.first +" "+text.name?.last)
    },
    userRole_Static !== "Doctor" &&
    {
      id: "practitioner",
      numeric: false,
      disablePadding: false,
      label: "Doctor/Receptionist",
      width:"25%",
      render:(text) => ((text?.name?.prefix ?? "")+""+text?.name?.first +" "+ text?.name?.last)
      
    },
   
    {
      id: "consultationType",
      numeric: false,
      disablePadding: false,
      label: "Consultation Type",
      render: (text:any, row:any) => {
        return(
          <>
          {TYPES[row?.consultationType]}
          </>
        )
      },
    },
    {
      id: "date",
      numeric: false,
      disablePadding: false,
      label: "Date",
      render: (text: any) => moment(text).format("DD/MM/YYYY"),
    },
    {
      id: "Time",
      numeric: false,
      disablePadding: false,
      label: "Time",
      render: (text: any, row:any) => { 
        let hour = Math.floor(row?.slotStartTime/3600000);
        let minute = (Math.floor(row?.slotStartTime/60000) % 60)
        return moment(`${hour}:${minute}`, "HH:mm").format("hh:mm A")      
        },
    },
    {
      id: "state",
      numeric: true,
      disablePadding: false,
      label: "Status",
      render: (text:any, row:any) => {
        return(
          <>
          {row?.gridStatus}
          </>
        )
      },
    },
    {
      id: "Action",
      numeric: false,
      disablePadding: false,
      label: "",
      render: (text:string, row:any) => {
          return (
            <IconButton onClick={handleRowOnclick.bind(null, row)} style={{height:"15px", width: "15px"}}>
                <ArrowForwardIosIcon style={{fontSize: 18}} />
            </IconButton>
          )
      }
    }
  ]

  const handleRowOnclick = (row:any) => {
    setOpenDetilsPopup(true)
    setSelData(()=>row)
  }


  return (
    <>
    {request.message && <Notify message={request.message} severity={request.severity} title={request.title}/>}
    <Grid className={classes.listView}>
      <AppBar position="static" className={classes.tabBar}>
        <Tabs
            value={activeTab}
            onChange={(e, value) => {
                setActiveTab(value);
                handlePageChange(1);
            }}
            scrollButtons="auto"
            variant="scrollable"
        >
            <Tab style={{fontSize: "14px"}} label = {type === "FollowupRequest" ? "Followup Requests" : "Upcoming"} value={1}/>
            <Tab style={{fontSize: "14px"}} label="History" value={2}/>
        </Tabs>
      </AppBar>
      <Table
        tableHeader={activeTab === 1 ? getTableHeaderUpcoming() : tableHeaderHistory}
        tableData={aptData}
        paginationServerSide={true}
        totalRecords={totalRecords}
        page={tablePage}
        rowsPerPage={rowsPerPage}
        sortingServerSide={true}
        onPageChange={handlePageChange}
        rowclickable={false}
        isLoading={isLoading}
        extra={
          <Grid className={classes.gridFooter}>
            Showing {tableLength} of {recordCount}
          </Grid>
        }
      />
    </Grid>
    </>
  )
}

export default ListView