import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { Grid, IconButton } from "@material-ui/core";
import useStyles from "./styles";
import { useHistory, useLocation } from "react-router";
import PatientInfo from "../../../components/Appointment/Consultation/PatientInfo";
import MedicalHistory from "../../../components/Appointment/Consultation/MedicalHistory";
import CaseSheet from "../../../components/Appointment/Consultation/CaseSheet";
import TabUi from "../../../components/Appointment/Consultation/TabConsultation";
import { joinConsultation, fetchInvestigation, fetchSymptoms, getPastConsultation, fetchMedicalRecords, chatRoomStatus } from "../../../api/consultation/consultation";
import { JoinConsultationData, Ilocation } from "../../../models/consultation";
import { genericObj } from "../../../models/common";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { USER_ROLES } from "../../../utils/constants";
import InstantChat from "./InstantChat";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import CustomSubmitButton from '../../../components/common/CustomSubmitButton';
import Button from '@material-ui/core/Button';
import Notify from "../../../components/common/CommonToast";
import { DisconnectMsg } from "../../../store/actions";
import {setfollowUp}from "../Consultation/FollowUp"
import { sendMsg } from "../../../components/Websocket/InstantChatIO";
import useUnsavedChangesWarning from "../../Hooks/useUnSavedChangesWarning";
import Skeleton from "./SkeletonLayout";
import { setReferInternal } from "../../../store/actions";
import { CircularProgress } from "@mui/material";
import pageloader from "../../../assets/images/loader/star_blue_loader.gif";
const LazyLoadRender = ({children}) => 
{
  const [show,setShow]=useState(false);
  return (
  <div style={{height :"100%",width:"100%"}}>
    <div style={{position : "absolute",display:"inline-block",top:"50%",left:"50%",transform:"translate(-50%,-50%)"}}>
     {!show && <CircularProgress  color="primary" style={{height : "5rem",width :"5rem"}}/>}
     <img src={pageloader} style={!show ? {display : "none"} : {width: '100px'}} onLoad={(event) => {
        console.log(event);
        setShow(true)
        }}/>
    </div>
    {children}
  </div>
  ) ;
}
const Consultation = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [unSavedChanges, setUnSavedChanges] = useState<any>(false)
  const location = useLocation<Ilocation>()
  const [data, setData] = useState<JoinConsultationData | null | undefined | any>(null)
  const [symptom, setSymptom] = useState<genericObj>({})
  const { bookedId, consultationId, consultationType, user, encounterID } = data ?? {}
  const { _id: userId } = user || {}
  const { data: investigationRes } = useQuery(["INVESTIGATIONS", ""], fetchInvestigation)
  const { data: medicalRecords , refetch : medicalRecRefetch} = useQuery(["MEDICAL_RECORDS", userId], fetchMedicalRecords, { enabled: !!userId })
  const investigationsArr = investigationRes?.data?.data || []
  const row: genericObj = location?.state?.row || {}
  const {chatdetails ,disconnectMsg} = useSelector((state: any) => state?.instantChat);
  const { instantdata, callID, bookedId:booked_Id } = useSelector((state: any) => state?.instantVideo)
  const Chat_Consulattion = location.pathname.includes('/chat-consulation');
  const { userRole, user:loggedUser } = useSelector((state: RootStateOrAny) => state?.auth);
  const roomID = new URLSearchParams(location.search).get("roomID")
  const [isInvalidRoom, setInvalidRoom] = useState<boolean>(false);
  const [poorNetwork,setpoorNetwork] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [patientLeftChat,setpatientLeftChat] =useState({loading:false,message:'',severity:''});
  const searchVal = location.search.split('?')[1];
  const [showChat,setshowChat]= useState(false);
  const [joinSucess,setjoinSucess]=useState(false);
  const [userLeft,setUserLeft]=useState({message : ""});
  let isCallBack = searchVal?.split('/').length > 1
  const [Prompt, setDirty, resetDirty] = useUnsavedChangesWarning();

  useEffect(()=>{
    if(loggedUser){
      sendMsg('sendSelfNotification',{'userId': loggedUser?._id, 'consultationStatus': Chat_Consulattion ? 'CHAT_ACCEPTED' : 'CALL_ACCEPTED'});
    }
  },[loggedUser])

const consultationJoin = async () => 
{
  setpatientLeftChat({loading:true , message:"",severity:""})
  try
  {
  const book_Id = isCallBack ? callID?.split('/')[0] : row?.row?._id ? row?.row?._id : instantdata?.bookedId ? instantdata?.bookedId : booked_Id;
  const call_Id = isCallBack ? callID?.split('/')[1] : instantdata?.callId;
  let payload = {
    bookedSlotId: book_Id
  }
  window.location.pathname === '/chat-consulation' ? payload['chatLogId']=chatdetails?.chatLogId : payload['callLogId'] = call_Id
  const response = await joinConsultation(payload);
    if (response && response?.status < 350) {
      setData(response.data);
      dispatch(setReferInternal(response?.data?.isReferral))
      setjoinSucess(true);
      setpatientLeftChat({loading:false,message:"",severity:""})
      if(window.location.pathname === '/chat-consulation')
      setshowChat(true);
    }
    else
    {
      if(window.location.pathname === '/chat-consulation')
      setpoorNetwork(true);
      setpatientLeftChat({loading:false,message:"",severity:""})
    }
  }
  catch(err)
  {
   setpatientLeftChat({loading:false,message:"",severity:""})
   if(window.location.pathname === '/chat-consulation')
   setpoorNetwork(true);
  }
}
   useEffect(() => {
    if ((row?.row?._id || instantdata?.bookedId || booked_Id) && (isCallBack ? callID : true) && window.location.pathname !== '/chat-consulation'){
      consultationJoin();
    }
  }, [row?.row?._id, callID, booked_Id])

const setUnSaved = (value : boolean) =>{
  setUnSavedChanges(value);
}
  useEffect(() => {
    if (sessionStorage.getItem("prescriptionId") && row?._id !== data?.bookedId) {
      sessionStorage.removeItem("prescriptionId")
    }
  }, [data])

  useEffect(() => {
    (async () => {
      const pageNoSymptoms: number = 1
      const response = await fetchSymptoms("SYMPTOMS", pageNoSymptoms);
      if (response) {
        setSymptom(response)
      }
    })();
  }, [])

  useEffect(() =>{
    setfollowUp ({
      isFollowUp:false,
      appointmentType:"FOLLOW_UP",
      modeOfBooking:"TELEBOOKING",
      appointmentCreatedBy:"",
      appointmentCreatedPersonRole: "",
      state:"APPOINTMENT_REQUESTED",
      consultationFee:0,
      claimAmount:0,
      slot:"",
      dayOfTheWeek:0,
      date:"",
      slotStartTime: "",
      isTelemedicine:true,
      parentAppointmentId:""
  });
  },[])
/* Toaster Message for Patient Disconnect in Instant Chat*/
useEffect(()=>{
  if(disconnectMsg)
  {
    setpatientLeftChat({loading:false,message : '' ,severity:''})
    setTimeout(() => {
      setpatientLeftChat({loading:false,message :disconnectMsg ,severity:'error'})
      dispatch(DisconnectMsg(''));
    },500)
  }
},[disconnectMsg])

  const { data: pastConsultationArray , isLoading:isPastLoading} = useQuery([userId, pageNumber], getPastConsultation, { enabled: userId });
  /* Room ID status for INSTANT CHAT */
  const RoomIdValidation = async (roomID , check) =>
  {
    try {
      setInvalidRoom(false);
      setpatientLeftChat({loading:true , message:"",severity:""});
      setUserLeft({message : ""});
      const res = await chatRoomStatus(roomID);
      if(res && res?.status < 350 && (res?.data?.status === 'AVAILABLE' || res?.data?.state === 'AVAILABLE' ))
      {
        setInvalidRoom(false);
        setUserLeft({message : res?.data?.message});
        check && consultationJoin();
      }
      else
      {
        setInvalidRoom(true);
        setpatientLeftChat({loading:true , message:res?.data?.message ?? "Facing Network/Techincal Problem",severity:""});
      } 
    }
    catch (e) {
      setInvalidRoom(true)
      setpatientLeftChat({loading:true , message:"Facing Network/Techincal Problem",severity:""})
    }
  }

/* Validation to differentiate old and New Consultation */
  useEffect(() => {
    if (window.location.pathname === '/chat-consulation') {
      if(roomID && chatdetails?.roomId)
      {
        if(roomID !== chatdetails?.roomId)
        {
          RoomIdValidation(roomID ,false);
        }
        else
        RoomIdValidation(roomID,true);
      }
      else
        RoomIdValidation(roomID,false);
    }
  }, [roomID,chatdetails?.roomId])

/* To perform reconnect -check in socket - need to know user has visited this page or not */
  useEffect(() => {
    if (window.location.pathname === '/chat-consulation') {
      sessionStorage.setItem('INSTANT_CHAT_INITIATED', 'true');
    }
    else if (window.location.pathname === '/InstantCall/room') {
      sessionStorage.setItem('INSTANT_CALL_INITIATED', 'true');
    }
  }, [])

  return (
    <>
    {userLeft.message && <Notify severity={patientLeftChat?.severity} message={userLeft?.message} />}
    {patientLeftChat.message && !isInvalidRoom  && <Notify severity={patientLeftChat?.severity} message={patientLeftChat?.message} />}
      {Chat_Consulattion && !isInvalidRoom && showChat && <InstantChat roomId={roomID} medicalRecRefetch={medicalRecRefetch}/>}
      {(patientLeftChat?.loading || (window.location.pathname === '/chat-consulation' && !joinSucess)) ?  <LazyLoadRender><Skeleton /></LazyLoadRender> : <>
      <PatientInfo userData={user && user} bookedId={bookedId} encounterID={encounterID} consultationType={consultationType} consultationDetails={data} unSavedChanges={unSavedChanges} resetDirty={resetDirty}/>
      <MedicalHistory userId={userId} />
      <div className={classes.SecondaryContainer}>
        {(userRole !== USER_ROLES.receptionist && userRole !== USER_ROLES.facility_receptionist) && <Grid container xs={6}>
          <CaseSheet
            investigationsArray={investigationsArr}
            symptomsArr={symptom?.data?.data}
            caseSheetId={consultationId ? consultationId : ""}
            data={data ? data : {}}
            patient={user ? user : {}}
          />
        </Grid>}
        <Grid container xs={userRole !== USER_ROLES.receptionist && userRole !== USER_ROLES.facility_receptionist ? 12 : 8}>
          <TabUi  pastConsultationArray={pastConsultationArray ? pastConsultationArray : []} data={data && data} medicalRecords={medicalRecords ? medicalRecords?.data : []} notAReceptionist={userRole !== USER_ROLES.receptionist && userRole !== USER_ROLES.facility_receptionist} setUnSavedChanges={setUnSaved} setPageNumber={setPageNumber} pageNumber={pageNumber} isPastLoading={isPastLoading} setDirty={setDirty} resetDirty={resetDirty}/>
        </Grid>
      </div>
      </>}
      <Dialog open={isInvalidRoom || poorNetwork} fullWidth={true} maxWidth="xs">
        <DialogTitle className={classes.titleRoot}>
          Permission denied
          <IconButton className="close-btn" onClick={() => {setInvalidRoom(false) }}>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <span style={{ fontWeight: 500, color: "#000000" }}>
              <div style={{ paddingTop: "8px" }}>
                {isInvalidRoom ? patientLeftChat?.message :"Facing Network/Techincal Problem"}
              </div>
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: '16px' }}>
          <Button onClick={() => {
            history.push("/Dashboard")
          }} className={classes.cancelBtn}>
            Close
          </Button>
          <CustomSubmitButton
            className={undefined}
            isLoading={false}
            onClick={() => { window.location.reload(); }}
          >
            Try Again
          </CustomSubmitButton>
        </DialogActions>
      </Dialog>
      {Prompt}
    </>
  );
};

export default Consultation;