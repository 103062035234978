import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@material-ui/core";
//import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import styles from "./styles";
import DummyDoctor from "../../../assets/images/dummy_doctor.jpg";
import { useDispatch, useSelector } from "react-redux";
import FileUploadButton from "../Consultation/InstantChatUpload";
import { getIcon } from "../../../utils";
import DocumentViewer from "../../../components/common/DocumentViewer";
import { CloseOutlined } from "@material-ui/icons";
import Notify from "../../../components/common/CommonToast";
import {
  ChatRoomDetails,
  ValueSet,
  sendMsg,
  setcurrentRoomID,
} from "../../../components/Websocket/InstantChatIO";
import clsx from "clsx";
import CloseIcon from "@mui/icons-material/Close";
import { chatHistoryList, endConsultationUpdate } from "../../../api/consultation/consultation";
import {
  EmptyMessagelist,
  NewMsgReceived,
  replaceMessagelist,
  NewNotifyUpd,
  UpdateTyping,
  UpdateChatToCallRequest,
  UpdateInstInfo,
  UpdateCallInfo,
  UpdateUserEnd,
  UpdateInstantType,
} from "../../../store/actions";
import { Button, CircularProgress } from "@mui/material";
import ReactDOM from "react-dom";
import SendIcon from "@mui/icons-material/Send";
import moment from "moment";
import { fileUploadCount } from "../../../api/InstantVideo/Instant";
import { getOnlineUserList } from "../../../api/Instant/InstantApi";
import RefreshIcon from '@mui/icons-material/Refresh';
import { v4 as uuidv4 } from 'uuid';
import VideocamIcon from '@mui/icons-material/Videocam';
import CustomDialog from "../../../components/common/CustomDialog";
import CustomCancelButton from "../../../components/common/CustomCancelButton";
import CustomSubmitButton from "../../../components/common/CustomSubmitButton";
import { useHistory } from "react-router-dom";
interface InstantChat {
  customizeClass?: string;
  hideChat?: boolean;
  sethideChat?: (value: boolean) => void;
  chathistory?: boolean;
  PatientName?: string;
  roomId?: string | undefined | null;
  isFollowupChat?: boolean;
  user_Id?: string;
  medicalRecRefetch?:() => void
}


/*Commneted For Temporarily */
// var TypingTimerSet;
// var isTyping = false;
var receiverTyping;
var senderTyping;
var RestartChat;
var sendedFlag = false;
var medicalRecfetch = false; 
const InstantChat = (props: InstantChat) => {
  const classes = styles();
  const dispatch = useDispatch();
  const fileCount = React.useRef(0);
  const [typeMessage, setTypeMessage] = useState<string>("");
  const [storeClientId,setStoreClientId]=useState<any>([]);
  const [chatDetails, setChatDetails] = useState({ patientID: { id: "" } });
  const { userRole, user,dbData } = useSelector((state: any) => state?.auth);
  const { Notifylist } = useSelector((state: any) => state?.NotifyList) ;
  const { connected, chatdetails, Chatmessages, newMsgReceived , updateTyping, chatbookedID} = useSelector((state: any) => state?.instantChat);
  const {userOnlineStatus, chatToCallRequest} = useSelector((state:any) => state?.instantVideo)
  const [urlPath,setUrlpath] = useState({url:[],KeyPath:[]});
  const [showDoc, setShowDoc] = useState(null);
  const [open, setOpen] = useState(true);
  const [pageNumber, setpageNumber] = useState(1);
  const [hasNext, sethasNext] = useState(false);
  const [loading, setLoading] = useState(false);
  const [scrollposition, setScrollPosition] = useState(false);
  const [onlineStatus, setOnlineStatus] = useState('');
  const [refetch , setRefecth] = useState(false);
  const [refechIcon,setRefecthIcon]= useState(true);
  const [restartChat,setRestartChat]=useState(false);
  const [scrollHeight,setscrollHeight]=useState<any>();
  const Date = {};
  const [sendLoad,setSendLoad]= useState(false);
  const [request, setRequest] = useState({
    loading: false,
    message: "",
    severity: "",
  });
  const [openDialog, setOpenDialog]= useState(false);
  const history = useHistory();
  const [showJoin, setShowJoin]= useState(false);

  const popMessage = (value) => {
    setRequest(value);
  };

  useEffect(()=>{
    if(chatdetails?.user || props?.user_Id){
      getUserOnlineStatus(chatdetails?.user || props?.user_Id)
    }
  },[chatdetails, props?.user_Id])

  useEffect(()=>{
    if(userOnlineStatus && userOnlineStatus[`${chatdetails?.primaryUserId || props?.user_Id}`]){
      if(userOnlineStatus[`${chatdetails?.primaryUserId || props?.user_Id}`]?.status === 'offline'){
        setOnlineStatus('yellow')
      } else {
        setOnlineStatus('#3DB712')
      }
    }
  },[userOnlineStatus])

  const getUserOnlineStatus = async(user_Id:string) => {
    try {
      const res = await getOnlineUserList(JSON.stringify([user_Id]))
      if(res){
        setOnlineStatus(res[0]?.status === 'offline' ? 'yellow' : '#3DB712')
      } else {
        setRequest({ message: "", loading: true, severity: "" });
        setRequest({
          message: "Something went wrong",
          loading: false,
          severity: "error",
        });
      }
    } catch(e){
      setRequest({ message: "", loading: true, severity: "" });
      setRequest({
        message: e || "Something went wrong",
        loading: false,
        severity: "error",
      });
    }
  }

  useEffect(() => {
    if (props.chathistory) {
      if (props?.hideChat) {
        OpenAccordian(true);
      }
    }
  }, [props?.hideChat]);

  useEffect(() => {
    if (props?.isFollowupChat) {
      if (connected) {
        sendMsg("join", { roomId: props?.roomId, type: "join" ,role : userRole});
        let payload = Notifylist ;
        delete payload?.[`${props?.roomId}`]
        dispatch(NewNotifyUpd(payload));
      }
    }
  }, [props?.isFollowupChat, connected]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setRefecthIcon(false);
        if (props?.roomId) {
          setcurrentRoomID(props?.roomId);
          const response = await chatHistoryList("GET-CHAT_HISTORY", {
            roomId: props?.roomId,
            pageSize: 10,
            pageNumber: 1,
            skip: Chatmessages.length + storeClientId.length,
            roleId: userRole,
          });
          if (response && response?.data?.roomData) {
            let roomData = {};
            const doctorData = response?.data?.roomData?.doctor;
            const patientData = response?.data?.roomData?.user;
            if (
              doctorData &&
              JSON.stringify(doctorData) !== "{}" &&
              patientData &&
              JSON.stringify(patientData) !== "{}"
            ) {
              roomData[doctorData._id] = { name: doctorData?.name };
              roomData[patientData._id] = { name: patientData?.name };
              roomData["patientID"] = { id: patientData._id };
            }
            ValueSet(roomData);
          }
          if (response && response?.data?.history) {
            const array: Array<Object> = response?.data?.history;
            const reverse = array.reverse();
            const reorderArray = [...reverse, ...Chatmessages];
            dispatch(replaceMessagelist(reorderArray));
            sethasNext(response?.hasNextPage);
            setRefecthIcon(false);
          }
          if(JSON.stringify(response?.data?.chatToCallRequestedCount) || JSON.stringify(response?.data?.chatToCallRequestStatus)){
            dispatch(UpdateChatToCallRequest({status: response?.data?.chatToCallRequestStatus, reqCount: response?.data?.chatToCallRequestedCount}))
          }
          if(JSON.stringify(response?.data?.isUserEnded)){
            dispatch(UpdateUserEnd(response?.data?.isUserEnded));
          }
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setRefecthIcon(true);
      }
    })();
  }, [props?.roomId, pageNumber , refetch]);

  const getFileName = (filename) => {
    if (filename) {
      const regex = /_[0-9]+.[a-z]+$/;
      const url = new URL(filename);
      const name = url.pathname.substring(url.pathname.lastIndexOf("/") + 1);
      const type = name.split(".").pop();
      const removeTimestamp = name.replace(regex, "") + "." + type;
      return removeTimestamp || "";
    } else return "";
  };
  useEffect(() => {
    if (showDoc) {
      OpenAccordian(false);
    }
  }, [showDoc]);
  useEffect(() => {
    Chatmessages && setDataList(Chatmessages);
    let chatdetail : any= ChatRoomDetails();
    JSON.stringify(chatdetail) !== "{}" &&
      setChatDetails({ ...chatDetails, ...chatdetail });
    (connected || props?.chathistory ) && OpenAccordian(true);
  }, [Chatmessages]);
  const [datalist, setDataList] = useState<Array<any>>();
 

  const OpenAccordian = (value) => {
    setOpen(value);
  };

  useEffect(() => {
    if (open && datalist && datalist.length > 0) {
      var objDiv = document.getElementById("chatDetails");
      if (objDiv !== null) {
        /* Whenever a new file is received medical records list is re-fecthed */ 
        if(newMsgReceived && datalist?.length > 0)
        {
          const index = datalist?.length - 1;
          if(datalist[index]?.isMultimedia)
          {
            if(!medicalRecfetch)
            {
              medicalRecfetch = true ;
              setTimeout(() => {
                props?.medicalRecRefetch && props?.medicalRecRefetch();
                medicalRecfetch = false;
              },1000)
            }
          }
        }
        if (!newMsgReceived && pageNumber > 1)
          objDiv.scrollTop = scrollposition
            ? Math.round(scrollHeight ? scrollHeight / (2 * (datalist.length / 20)): objDiv.scrollHeight / (2 * (datalist.length / 20)))
            : objDiv.scrollHeight;
        else {
          objDiv.scrollTop = objDiv.scrollHeight;
          dispatch(NewMsgReceived(false));
        }
        setScrollPosition(true);
      }
      getURLText();
    } else if (!open) setScrollPosition(false);
  }, [datalist, open ]);

  const sendFile = () => {
    if (urlPath?.KeyPath?.length !== 0) {
      urlPath?.KeyPath.map((key: string) => {
        const client_id = uuidv4();
        let client : any = [...storeClientId];
        client.push(client_id);
        setStoreClientId(client);
        sendMsg("sendMessage", {
          userId: user?._id,
          // name: userName,
          room: props?.chathistory ? props?.roomId : chatdetails?.roomId,
          text: key,
          isMultimedia: true,
          client_id:client_id,
        });
        fileCount.current = fileCount.current + 1;
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      sendFile();
      setSendLoad(true);
      e.preventDefault();
    }
  };
  const userName = (value) => {
    return (
      value?.name?.first[0].toUpperCase() +
      value?.name?.first.slice(1) +
      ` ${
        value?.name?.last
          ? value?.name?.last[0].toUpperCase() + value?.name?.last.slice(1)
          : ""
      }`
    );
  };
  useEffect(() => {
    (async () => {
      const payload = {
        senderId: user?._id,
        roomId: props?.roomId,
        isMultimedia: true,
      };
      try {
        const response = await fileUploadCount("File-Count", payload);
        if (response) {
          fileCount.current = response?.data?.chatHistoryCount;
        }
      } catch (err) {
        popMessage({
          loading: false,
          message:
            err?.response?.data?.message || "Error Occured while uploading!",
          severity: "",
        });
      }
    })();
    return () => {
      dispatch(EmptyMessagelist());
      /*Commneted For Temporarily */
      // isTyping && sendMessage(false);
      // isTyping = false;
      // clearTimeout(TypingTimerSet);
      setcurrentRoomID('');
      sendMsg('removeSocket',{role:userRole,roomId:props?.roomId});
    };
  }, []);

// useEffect(() => {
//   if(updateTyping && typeof updateTyping === 'object' && updateTyping[`${chatDetails?.patientID?.id}`]?.isTyping)
//   {
//     updateTyping[`${chatDetails?.patientID?.id}`].isTyping = false ;
//     dispatch(UpdateTyping(updateTyping));
//   }
// },[connected,chatDetails])
const setTypingFalse = () => 
{
  if(updateTyping && typeof updateTyping === 'object' && chatDetails?.patientID?.id && updateTyping[`${chatDetails?.patientID?.id}`]?.isTyping)
  {
    updateTyping[`${chatDetails?.patientID?.id}`].isTyping = false ;
    dispatch(UpdateTyping(updateTyping));
  }
}
/* Reset value */
const startNew = () => {
  setTypingFalse();
  setRefecth(!refetch);
  setpageNumber(1);
  dispatch(EmptyMessagelist());
  setSendLoad(false);
  setTypeMessage("");
  setStoreClientId([]);
  setUrlpath({ url: [], KeyPath: [] });
  setRestartChat(false);
  medicalRecfetch = false;
  props?.medicalRecRefetch && props?.medicalRecRefetch();
}
/*Websocket disconnected and connected */
useEffect(() => {
  if(connected)
  startNew();
  else
  setTypingFalse();
},[connected])

  const RenderIcon = (imageUrl) => {
    if(!sendedFlag)
    {
     clearTimeout(senderTyping);
     sendedFlag= true;
     senderTyping = setTimeout(() =>{sendMessage(true);sendedFlag = false;},1000);
    }
    return (
      <a href={imageUrl} target="_blank" rel="noopener noreferrer">
        <img src={getIcon(imageUrl)} alt="document" width="40" height="40" />
      </a>
    );
  };

  const getURLText = () => {
    datalist?.forEach((ele: any, index: any) => {
      if(!ele?.type){
        const message = ele?.text;
        var msgWithChar60or62 = message.replace(/<|>/g, (char) => {
          var char60or62 = char;
          if (char60or62 === "<") {
            return "<span>&lt;</span>";
          } else if (char60or62 === ">") {
            return "<span>&gt;</span>";
          }
        });
        var urlRegex =
          /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=-]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&\/=]*)/gi;
        var msg = msgWithChar60or62.replace(urlRegex, function (url) {
          var hyperlink = url;
          if (!hyperlink.match("^https?://")) {
            hyperlink = "http://" + hyperlink;
          }
          const color =
            ele?.user === user?._id ? "#ffffff !important" : "#000000 !important";
          return `<a href=${hyperlink} style="color: ${color}; text-decoration: underline" target="_blank" rel="noopener noreferrer">${url}</a>`;
        });
        const value = document?.getElementById(`message-${index}`);
        if (value !== null) {
          value.innerHTML = msg;
        }
      }
    });
  };

  const sendMessage = (check: boolean) => {
    sendMsg("sendTypingStatus", {
      userId: user?._id,
      roomId: props?.roomId,
      isTyping: check,
    });
  /*Commented This Line Temporarily */
  //  isTyping = check;
  };

  /*Commented This Line Temporarily */
  // const TypingCheck = (value: string) => {
  //   function setTime() {
  //     TypingTimerSet = setTimeout(() => {
  //       sendMessage(false);
  //     }, 3000);
  //   }
  //   switch (value) {
  //     case "RESET":
  //       clearTimeout(TypingTimerSet);
  //       setTime();
  //       break;
  //     default:
  //       clearTimeout(TypingTimerSet);
  //   }
  // };

/*Typing Only send true from web after 3sec set in store as False */
useEffect(() => {
  if(updateTyping[chatDetails?.patientID?.id]?.isTyping)
  {
    clearTimeout(receiverTyping);
    receiverTyping =  setTimeout(() => {
      updateTyping[`${chatDetails?.patientID?.id}`].isTyping = false ;
      dispatch(UpdateTyping(updateTyping));
    },5000)
  }
},[updateTyping[chatDetails?.patientID?.id]?.isTyping])

/* After All message Received clear the message and loading */
useEffect(() => {
  if(storeClientId.length <= 0 && sendLoad)
  {
    setSendLoad(false);
    setRestartChat(false);
    setTypeMessage('');
    setUrlpath({ url: [], KeyPath: [] });
  }
},[storeClientId])

useEffect(() => {
  if(sendLoad)
  {
    RestartChat = setTimeout(() => {
       setRestartChat(true);
    },6000)
  }
  else
  {
    clearTimeout(RestartChat);
    document.getElementById("instant-chat-text")?.focus();
  }
  
},[sendLoad])

  const renderCallRequestUI = (ele:any) => {
    return(
      <div className={classes.callRequest}>
        {ele?.doctorText}
      </div>
    )
  }

  const renderCallAccecptUI = (ele:any,index:number,type:string) => {
    if(type === "RequestAccepted" && !showJoin) {
      setShowJoin(true);
      dispatch(UpdateChatToCallRequest({...chatToCallRequest, status: "RequestAccepted"}));
    } 
    return(
      <Grid className={classes.receiverStyle}>
        <Grid className={classes.receivermessage}>
          <span className="TextWrap">
            <p
              style={{
                      whiteSpace: "pre-wrap",
                      margin: 0,
                    }}
              id={`message-${index}`}
            >
              {ele?.doctorText}
            </p>
          </span>
        </Grid>
      </Grid>
    )
  }

  const handleConvertChatToCall = async () => {
    try{
      const payload ={
        bookedId : chatbookedID,
        role: userRole,
        consultType: "CHAT",
        reason: dbData?.CHAT_TO_CALL_ENDING_DISPOSITION_REASON_ID ?? "",
        comments: dbData?.CHAT_TO_CALL_ENDING_DISPOITION_COMMENT ?? "",
        reference: user?._id,
        isConvertChatToCall: true
      }
      const res = await endConsultationUpdate("endChat", payload)
      if(res){
        dispatch(UpdateInstantType(''))
        dispatch(UpdateInstInfo(res[1]));
        dispatch(UpdateCallInfo(true));
        history.push({
          pathname: "/InstantCall/room",
          search: `${res[1]?.roomId}`,
          state: { row: { row: { _id: res[1]?.bookedId } } },
        });
      } else {
        setRequest({ message: "", loading: true, severity: "" });
        setRequest({
          message: res?.message || "Something went wrong",
          loading: false,
          severity: "error",
        });
        setOpenDialog(false)
      }
    }catch(e){
      setRequest({ message: "", loading: true, severity: "" });
      setRequest({
        message: e?.response?.data?.message || "Something went wrong",
        loading: false,
        severity: "error",
      });
      setOpenDialog(false);
    }
  }

  const isShowTooltip = () => {
    const textElement = document.getElementById('textElement');
    if(textElement){
      return textElement.scrollWidth > textElement.clientWidth
    }
  }

  return ReactDOM.createPortal(
    <div>
      {request.message && (
        <Notify message={request.message} severity={request.severity} />
      )}
      <div className={props.customizeClass || classes.outerBox}>
        <Accordion
          className={clsx(classes.mainNormalAccordion, classes.IconButton)}
          expanded={open}
        >
          <AccordionSummary
            aria-controls={`accordion-content}`}
            id={`accordion-header`}
            expandIcon={<ExpandLessIcon style={{ fontSize: "24px" }} />}
            onClick={() => {
              OpenAccordian(!open);
            }}
          >
            <div style={{display: 'flex'}}>
              <img
                className={classes.userPhoto}
                src={chatdetails?.photo || DummyDoctor}
                loading="lazy"
                decoding="async"
              />
              <div className={classes.DoctorOnlinestatus} style={{background: `${onlineStatus}`}}/>
            </div>
            <Tooltip 
              title={isShowTooltip() ? props?.chathistory ? props?.PatientName : chatdetails?.name : ""}>
              <Typography className={(!props?.chathistory && showJoin) ? classes.chatUserName_CallConversion  : classes.chatUserName} id="textElement">
                {props?.chathistory ? props?.PatientName : chatdetails?.name}
              </Typography>
            </Tooltip>
            {
              !props?.chathistory && showJoin && 
                <Button className={classes.joinCallBtn} onClick={() => {setOpenDialog(true)}}>
                  <span style={{marginRight:"4px"}}>Join</span>
                  <VideocamIcon />
                </Button>
            }
            {props?.chathistory && (
              <IconButton
                onClick={() => {
                  props.sethideChat && props.sethideChat(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </AccordionSummary>
          {refechIcon && !restartChat && <div style={{textAlign : "center"}}><IconButton onClick={() => setRefecth(!refetch)}><RefreshIcon color={'primary'} fontSize="small"/></IconButton></div>}
          {restartChat && connected &&  <div style={{textAlign : "center",color:"red"}}>Network issue. Please click refresh here <IconButton onClick={() => (startNew(),setRestartChat(false))}><RefreshIcon color={'error'} fontSize="small"/></IconButton></div>}
          <AccordionDetails
            className={classes.chatDetails}
            id="chatDetails"
            onScroll={(event) => {
              const listboxNode = event.currentTarget;
              if (listboxNode.scrollTop < 1 && hasNext && scrollposition && connected && !loading) {
                datalist && datalist.length > 0 ? setpageNumber(pageNumber + 1) : setpageNumber(1);
                setscrollHeight(listboxNode.scrollHeight);
              }
            }}
          >
            {/* {reconnecting && (
              <div className={classes.reconnect}>
                <CircularProgress color="primary" className="loader" />
                <label className="text">Reconnecting</label>
              </div>
            )} */}
            <Grid style={{ width: "-webkit-fill-available" }}>
              {loading &&  <CircularProgress style={{ marginLeft: "44%" }} />}
              {datalist &&
                datalist.length > 0 &&
                datalist.map((ele: any, index: any) => {
                  if(storeClientId.includes(ele?.client_id))
                  {
                       let clientIds = [...storeClientId];
                       let indexId = clientIds.indexOf(ele?.client_id);
                       clientIds?.length > 1 ?  clientIds.splice(indexId,1) : clientIds = [];
                       setStoreClientId(clientIds);
                  }
                  const date = moment(ele?.createdAt).format("DD/MM/YYYY");
                  !Date[date]
                    ? (Date[date] = 1)
                    : (Date[date] = Date[date] + 1);
                  return (
                    <div>
                      <div className={classes.Date}>
                        {Date[date] === 1
                          ? moment().format("DD/MM/YYYY") === date
                            ? "Today"
                            : date
                          : ""}
                      </div>
                      {ele?.type && (ele?.type === "InstantCallRequest" || ele?.type === "RequestRejected") ? 
                      renderCallRequestUI(ele) :
                      ele?.type && ele?.type === "RequestAccepted" ?
                      renderCallAccecptUI(ele,index, ele?.type)
                      : ele?.user === user?._id ? (
                        <div>
                          <Grid className={classes.senderStyle}>
                            <Grid>
                              <Typography
                                component="p"
                                className={classes.sendername}
                              >
                                {`Dr. ${userName(chatDetails[ele?.user])}`}
                              </Typography>
                            </Grid>
                            <Grid className={classes.sendermessage}>
                              {!ele?.isMultimedia ? (
                                <span className="TextWrap">
                                  <p
                                    style={{
                                      whiteSpace: "pre-wrap",
                                      margin: 0,
                                    }}
                                    id={`message-${index}`}
                                  >
                                    {ele?.text}
                                  </p>
                                </span>
                              ) : (
                                <div
                                  className={classes.DocumentName}
                                  onClick={() => {
                                    setShowDoc(ele?.text);
                                  }}
                                >
                                  <img
                                    src={getIcon(ele?.text)}
                                    alt="document"
                                    width="40"
                                    height="40"
                                  />
                                  <Tooltip title={getFileName(ele?.text)}>
                                    <p className={"filename"}>
                                      {getFileName(ele?.text)}
                                    </p>
                                  </Tooltip>
                                </div>
                              )}
                            </Grid>
                            <div className="DateTime">
                              {moment(ele?.createdAt).format("LT")}
                            </div>
                          </Grid>
                        </div>
                      ) : (
                        <Grid className={classes.receiverStyle}>
                          <Grid>
                            <Typography
                              component="p"
                              className={classes.receivername}
                            >
                              {userName(chatDetails[ele?.user])}
                            </Typography>
                          </Grid>
                          <Grid className={classes.receivermessage}>
                            {!ele?.isMultimedia ? (
                              <span className="TextWrap">
                                <p
                                  style={{
                                    whiteSpace: "pre-wrap",
                                    margin: 0,
                                  }}
                                  id={`message-${index}`}
                                >
                                  {ele?.text}
                                </p>
                              </span>
                            ) : (
                              <div
                                className={classes.DocumentName}
                                onClick={() => {
                                  setShowDoc(ele?.text);
                                }}
                              >
                                <img
                                  src={getIcon(ele?.text)}
                                  alt="document"
                                  width="40"
                                  height="40"
                                />
                                <Tooltip title={getFileName(ele?.text)}>
                                  <p className={"filename"}>
                                    {getFileName(ele?.text)}
                                  </p>
                                </Tooltip>
                              </div>
                            )}
                          </Grid>
                          <div className="DateTime">
                            {moment(ele?.createdAt).format("LT")}
                          </div>
                        </Grid>
                      )}
                    </div>
                  );
                })}
            </Grid>
          
          </AccordionDetails>
          <Grid >  
             {connected ? updateTyping && typeof updateTyping === "object" && updateTyping[chatDetails?.patientID?.id]?.isTyping  && <Typography key={chatDetails[`${chatDetails?.patientID?.id}`]?.name?.first} className={classes.IsTyping}>{chatDetails[`${chatDetails?.patientID?.id}`]?.name?.first ?? ""}{` is typing...`}</Typography> : <Typography  color="error" style={{width :"100%",textAlign : "center",fontSize:"0.75rem"}}>Poor Connection. Please check your connection</Typography>}
            </Grid>
          {(props?.chathistory ? props?.isFollowupChat : true) && (
            <AccordionDetails style={!connected ? {opacity : '0.6'} :{}}>
              {
                <FileUploadButton
                  setTypeMessage={setTypeMessage}
                  fileCount={fileCount}
                  roomId={props?.chathistory ? props?.roomId : chatdetails?.roomId}
                  connected={!connected || sendLoad}
                  popMessage={popMessage}
                  chatParticipantInfo={{
                    isGroup: false,
                    chatUserId: "",
                    isActive: false,
                  }}
                  handleKeyDown={handleKeyDown}
                  setUrlpath={(value) => {
                    setUrlpath({ url: value?.url, KeyPath: value?.keypath });
                  }}
                  user_id={
                    props?.chathistory
                      ? chatDetails?.patientID?.id
                      : chatdetails?.user
                  }
                />
              }
              {urlPath?.url?.length === 0 ? (
                <TextareaAutosize
                  className={classes.chatText}
                  style={{ overflow: "auto" }}
                  id="instant-chat-text"
                  placeholder="Type a message"
                  value={typeMessage}
                  disabled={!connected || sendLoad || request.loading}
                  onKeyPress={(e) => {
                    if (
                      e?.key === "Enter" &&
                      typeMessage.trim() &&
                      !e.shiftKey
                    ) {
                      e.preventDefault();
                      setSendLoad(true);
                      clearTimeout(senderTyping);
                      sendedFlag = false;
                     // setTypeMessage("");
                     const client_id = uuidv4();
                     let client : any = [...storeClientId];
                     client.push(client_id);
                     setStoreClientId(client);
                      sendMsg("sendMessage", {
                        userId: user?._id,
                        text: typeMessage.trim(),
                        isMultimedia: false,
                        room: props?.roomId,
                        client_id : client_id,
                      });
                    }
                  }}
                  onChange={(e) => {
                    if(!sendedFlag)
                    {
                      clearTimeout(senderTyping);
                      sendedFlag= true;
                      senderTyping = setTimeout(() =>{sendMessage(true);sendedFlag = false;},800);
                    }
                    //TypingCheck("RESET");
                    if (!typeMessage) {
                      e.target.value !== "\n" && setTypeMessage(e.target.value);
                    } else {
                      setTypeMessage(e.target.value);
                    }
                  }}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                 <div className={classes.iconWrapper}>{urlPath?.url?.map(RenderIcon)}</div>
                  <IconButton
                    onClick={() => {
                      setUrlpath({ url: [], KeyPath: [] });
                    }}
                  >
                    <CloseOutlined />{" "}
                  </IconButton>
                </div>
              )}
              <IconButton
               disabled={!connected || sendLoad || request.loading}
                onClick={() => {
                  if (urlPath?.url.length === 0) {
                    if (typeMessage.trim()) {
                      const client_id = uuidv4();
                      let client : any = [...storeClientId];
                      client.push(client_id);
                      setStoreClientId(client);
                      clearTimeout(senderTyping);
                      sendedFlag= false;
                      sendMsg("sendMessage", {
                        userId: user?._id,
                        text: typeMessage.trim(),
                        isMultimedia: false,
                        room: props?.roomId,
                        client_id : client_id,
                      });
                      setSendLoad(true);
                     // setTypeMessage("");
                    }
                  } else {
                    sendFile();
                    setSendLoad(true);
                  }
                }}
              >
                {sendLoad ? <CircularProgress color="primary" style={{ marginTop: "6px" ,height : "20px",width :"20px"}}  /> : <SendIcon color="primary" style={{ marginTop: "6px" }} />}
              </IconButton>
            </AccordionDetails>
          )}
        </Accordion>
      </div>
      {showDoc && (
        <DocumentViewer
          open={showDoc}
          onClose={() => {
            setShowDoc(null);
          }}
          title={"View Document"}
          documents={[{ uri: showDoc }]}
        />
      )}
      <CustomDialog
        title={"Send Instant Call Request"}
        open={openDialog}
        autoWidth={true}
        size='md'
        enableFooter
        onClose={() => {setOpenDialog(false)}}
        CloseIcon={<CloseIcon />}
        className={classes.TagCustomModel}
        classes={{ paperFullWidth: classes.TagcustomDialog }}
        dialogFooter={
          <>
            <Grid item sm={12} className={classes.fullWidthButton}>
              <CustomCancelButton
                type="button"
                onClick={() => {setOpenDialog(false)}}
              >
                Cancel
              </CustomCancelButton>
              &nbsp;&nbsp;&nbsp;
              <CustomSubmitButton
                className
                onClick={handleConvertChatToCall}
              >
                Continue
              </CustomSubmitButton>
            </Grid>
          </>
        }
      >
        <Box>
        <Grid item xs={12}>
        <h4 className={classes.title}>{
          `Are you sure want to send an Instant Call request to ${props?.chathistory ? props?.PatientName : chatdetails?.name}?`
        }</h4>
        </Grid>
        </Box>
      </CustomDialog>
    </div>,
    document.body
  );
};

export default InstantChat;
