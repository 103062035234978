import { Button } from '@material-ui/core';
import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';
import EndCall from '../../../../assets/images/consultation/EndCall.svg';
import { useLocation } from 'react-router';
import { roomDeleteApi } from '../../../../api/InstantVideo/Instant';
import Notify from '../../../Notify';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { UpdateRoomStatus } from '../../../../store/actions';

export default function EndCallButton({reference, isVideoCollapsed,className,setRoomStatus}:any) {
  const { room } = useVideoContext();
  const dispatch = useDispatch();
  const location = useLocation();
  const [request, setRequest] = useState({
    loading: false,
    message: "",
    severity: "",
  });
  const headers = new window.Headers();
  const { row }: any = location?.state || {}
  const endCall = async () => {
    let room_name = ''
    if (location.pathname === '/Appointments/room')
      room_name = row?.row?._id;
    else  {
      const searchVal = location.search.split('?')[1];
      if(searchVal?.split('/').length > 1){
        room_name = searchVal?.split('/')[1];
      } else {
        room_name = searchVal;
      }
    }
      
      
try
{
  setRequest({
    loading: true,
    message: "",
    severity: "",
  });
  const response = await roomDeleteApi('Room_Deletion_Api',room_name);
  if(response?.status === 200)
  {
    headers.set('content-type', 'application/json');
    if(!isVideoCollapsed){
      reference()
    }
    setRoomStatus("UNAVAILABLE")
    dispatch(UpdateRoomStatus(false));
    room!.disconnect()
  }
  else
  setRequest({
    loading: false,
    message: response?.data?.message || "Something went wrong",
    severity: "",
  });
}
catch (err)
{
  setRequest({
    loading: false,
    message: err?.response?.data?.message || "Something went wrong",
    severity: 'error',
  })
}
  };

  return (
    <>
    {request.message && (
        <Notify message={request.message} severity={request.severity} />
      )}
    <Button
    className={className}
      onClick={() => {
        endCall();
      }}
      startIcon={<img src={EndCall} />}
      data-cy-disconnect
    ></Button>
    </>
  );
}
