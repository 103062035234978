import { combineReducers } from "redux";

import auth from "./auth";
import register from "./register";
import clinic from "./clinic";
import user from "./user";
import careProgram from "./careProgram";
import chat from "./chat"
import call from "./pickup"
import instantVideo from './instantVideo'
import OnlineDoctors from './OnlineDoctors'
import instantChat from './instantChat';
import showConsultation from './consultation';
import NotifyList from './Notification'
import lowLetency from "./lowLetency";

const reducers = combineReducers({ auth, register, clinic, user, careProgram,chat ,call,instantVideo,OnlineDoctors,instantChat,showConsultation,NotifyList,lowLetency});

export default reducers;
