import { CircularProgress, Grid, IconButton, Radio, RadioGroup, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Controller, ControllerRenderProps, Control } from "react-hook-form";
import { useQuery } from "react-query";
import Wallet from "../../../assets/images/icons/wallet_inactive.svg";
import { ReactComponent as VirtualConsult } from "../../../assets/images/icons/video_consult_inactive.svg";
import styles from "./styles";
import CommonDropDown from "../../../components/common/CommonDropDown";
import CommonMenuItem from "../../../components/common/CommonDropDown/CommonMenuItem";
import CommonTextFieldSearch from "../../../components/common/CommonTextFieldSearch";
import PatientListing from "./patientListing";
import { RootStateOrAny, useSelector } from "react-redux";
import CommonDatePicker from "../../../components/common/CommonDatePicker";
import { consultationType } from "./consultation.json";
import {genericObj, IAppointmentPayload, Irequest } from "../../../models/common";
import PolicyListing from "./policyListing";
import CommonTextField from "../../../components/common/CommonTextField";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import moment from "moment";
import CustomFormEM from "../../../components/common/CustomFormEM";
import { consultationTypeByFacility, getAllSpeciality, getSymptoms, getSlotList, getUserDetailBySearch, getpolicydetails } from '../../../api/AppointmentManagement/Appointment'
import CommonSearchMultiselect from "../../../components/common/CommonSearchMultiselect";
import { getAllPractitioners } from "../../../api/scheduler/slot-management";
import { USER_ROLES } from "../../../utils/constants";
import CustomCheckboxLabel from "../../../components/common/CustomCheckbox";

interface IBookAppointment {
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  setConsultationFee: React.Dispatch<React.SetStateAction<string>>;
  appointmentPayload: IAppointmentPayload;
  setAppointmentPayload: React.Dispatch<React.SetStateAction<IAppointmentPayload>>;
  request: Irequest;
  bookingState: string;
  setRequest: React.Dispatch<React.SetStateAction<Irequest>>;
  initialPayload: IAppointmentPayload;
  detailsData: any;
  facilityId: string;
  Reasons: any;
  checked:any;
  setChecked:any;
  reset:(value:any)=>void;
  setValue:(name: any, value: unknown, config?: any) => void;
  getValues:(payload?: string | string[]) => any;
  trigger:()=>void;
  errors:genericObj;
  control:Control<Record<string, any>>;
  userData:any;
  isInstant:boolean;
}

export interface IPatientDetail {
  _id: string;
  countryCode: string;
  gender: string;
  secondaryMobile?: null[] | null;
  profileStatus: string;
  loginAttemptFailedAt: string;
  totalFailedLoginAttempt: number;
  loginRetryDelay: number;
  mobile: string;
  name: IPatientName;
  addresses?: null[] | null;
  mobileDevices?: null[] | null;
  insurances?: null[] | null;
  onboardingQuestions?: null[] | null;
  createdAt: string;
  updatedAt: string;
  __v: number;
}
export interface IPatientName {
  _id: string;
  first: string;
  last: string;
}

const BookAppointment = ({
  setDisabled,
  setConsultationFee,
  appointmentPayload,
  setAppointmentPayload,
  request,
  bookingState,
  setRequest,
  initialPayload,
  detailsData,
  facilityId,
  Reasons,
  checked,
  setChecked,
  reset,
  setValue,
  getValues,
  trigger,
  errors,
  control,
  userData,
  isInstant
}: IBookAppointment) => {
  const classes = styles();
  const [specialitieslist, setspecialitieslist] = useState<any>([]);
  const [search, setSearch] = useState<string>("");
  // const [isAddPatient, setIsAddPatient] = useState<boolean>(false);
  const [tablePage, setTablePage] = useState<number>(1);
  const rowsPerPage = 10;
  const { userRole, user } = useSelector((state: RootStateOrAny) => state?.auth);
  const { isReferInternal } = useSelector((state : any)=> state?.instantVideo);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  // const [loading, setLoading] = useState<boolean>(false);
  const [patientsData, setPatientsData] = useState<IPatientDetail[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [anchorEl2, setAnchorEl2] = React.useState<HTMLElement | null>(null);
  const [selectedDoctor, setSelectedDoctor] = useState<any>(null);
  const [consultType, setConsultType] = useState<string>("");
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null);
  const [timeSlotList, setTimeSlotList] = useState<any>([]);
  const [specialityId, setSpecialityId] = useState<string>("");
  const [selectedPatient, setSelectedPatient] = useState<IPatientDetail | null>(null);
  const [specialitiesPage, setSpecialitiesPage] = useState<number>(1);
  const [searchSpeciality, setsearchSpeciality] = useState('');
  const [searchdoctor, setsearchdoctor] = useState<any>('');
  const [DoctorPage, setDoctorPage] = useState<number>(1);
  const [practitionersList, setPractitionersList] = useState<any>([]);

  const newSpeciality =specialitieslist?.length >0 ? specialitieslist?.filter((tag, index, array) => array.findIndex(t => t._id == tag._id && t.name == tag.name) == index) : [];
 
  
  useEffect(() => {
    if (user && userRole === "UNIFIED_APP_DOCTOR") {
      setSelectedDoctor(user);
    }
  }, [user, userRole])

  const { data: facilityUsersList, isFetching: facilityUsersListFetching,refetch: refetchDetail } = useQuery(
    [search, rowsPerPage, 0, rowsPerPage, tablePage],
    getUserDetailBySearch,
    { enabled: search !== "" }
  );

  const { data: consultationTypeList = [], isFetching: consultationTypeFetching } = useQuery(
    ["consultationTypeByFacility",
      (appointmentPayload.state === "RESCHEDULE" || appointmentPayload.state === "FOLLOWUP") ? detailsData?.facilityId : facilityId,
      selectedPatient],
    consultationTypeByFacility,
    { enabled: selectedPatient !== null || appointmentPayload.state === "RESCHEDULE" }
  );

  const { data: specialityList = [], isFetching: specialitylistFetching } = useQuery(
    [10, specialitiesPage, searchSpeciality],
    getAllSpeciality
  );
  
  useEffect(()=>{
    if(isInstant){  
      if(!appointmentPayload?.isReferral){
        setAppointmentPayload({
          ...appointmentPayload,
         practitioner: user?._id,
         });
         setValue("doctor",`${user?.name?.prefix +user?.name?.first +" "+user?.name?.last}`)
         setSelectedDoctor(user)
      }
      else {
          setAppointmentPayload({
            ...appointmentPayload,
           practitioner: '',
           });
           setTimeSlotList([])
           setSelectedDoctor(null);
           setValue("doctor",null)
          setDoctorPage(1) 
      }
    } 
  },[appointmentPayload?.isReferral])

  
  useEffect(() => {
    if (specialityList.length > 0) {
      specialityList.map((data:any)=>{
        data.name = data?.providerSpeciality
       })
      if (specialitiesPage === 1) {
        setspecialitieslist([...specialityList]);
      }
      else {
        setspecialitieslist([...specialitieslist, ...specialityList]);
      }
    }
  }, [specialityList])

  useEffect(() => {
    if (facilityUsersList) {
      if (tablePage === 1) {
        setPatientsData([...facilityUsersList.data]);
      } else {
        setPatientsData([...patientsData, ...facilityUsersList.data]);
      }
    }
  }, [facilityUsersList]);

  const { data: consultationReason = [], isFetching: consultationReasonFetching } = useQuery(
    [10, specialitiesPage, 0],
    getSymptoms
  );

  const { data: policydetail, isFetching: policydetailfetching } = useQuery(
    [selectedPatient?.mobile],
    getpolicydetails,
    { enabled: (selectedPatient !== null && appointmentPayload.patientType === "policyHolder") }
  );

  useEffect(() => {
    setSelectedDate(null);
    setValue("appointmentTime", "");
    setValue("searchPatient", "")
    setSelectedPatient(null);
    // setIsAddPatient(false);
    if(isInstant){
      setAppointmentPayload({ ...appointmentPayload, patientType: "nonPolicyHolder", user: userData?._id });
      setSelectedPatient({ ...userData });
      setValue("patientSearch", userData?.userName)
    } else {
      setAppointmentPayload({ ...appointmentPayload, patientType: "nonPolicyHolder" });
    }
  }, [])

  const { data: practitioners = [], isFetching: searchingDoctor } = useQuery(
    ["GET-ALL-PRACTITIONERS", 'UNIFIED_APP_DOCTOR', facilityId,
      { search: searchdoctor, pageSize: 10, pageNumber: DoctorPage, active: "true", consultationType: "VIRTUAL_CONSULTATION" }],
    getAllPractitioners);

  const { data: timeSlots, isFetching: timeSlotFetching } = useQuery(
    ["getSlotList",
      {
        facilityId: facilityId,
        practitionerId:(userRole === "UNIFIED_APP_DOCTOR"  && appointmentPayload?.isReferral === false) ? user?._id : appointmentPayload?.practitioner,
        primaryUserId: selectedPatient?._id ?? appointmentPayload.user,
        roleId: 'UNIFIED_APP_DOCTOR',
        consultType: consultType,
        date: moment(selectedDate).format("YYYY/MM/DD"),
        "isTelemedicine":true,
        ...((appointmentPayload?.state === "RESCHEDULE" && detailsData?.bookedSlotId) && {"bookedSlotId":detailsData?.bookedSlotId}) 
      }
    ],
    getSlotList,
    { enabled: (selectedDoctor !== null && selectedDate) && (selectedPatient || appointmentPayload.user),retry:0}
  );

  useEffect(() => {
    if (practitioners && practitioners.length > 0) {
      let list: any = []
      practitioners.forEach((data: any) => {
        list.push({ id: data._id, name: `Dr.${data.name.first} ${data.name.last}` })
      });
      if (appointmentPayload.state === "RESCHEDULE" || appointmentPayload.state === "FOLLOWUP") {
        if (list && userRole !== "UNIFIED_APP_DOCTOR") {
          list?.forEach((data: any) => {
            if (data?.id === detailsData?.practitioner?._id) {
              setSelectedDoctor(data);
            }
          })
        }
      }
      setPractitionersList([...practitionersList, ...list])
    }
  }, [practitioners, DoctorPage])

  useEffect(() => {
    if (appointmentPayload.state === "RESCHEDULE" || appointmentPayload.state === "FOLLOWUP") {
      setValue('appointmentType', detailsData?.consultationType)
      setConsultType(appointmentPayload.state === "FOLLOWUP" ? "VIRTUAL_CONSULTATION" :detailsData?.consultationType)
      setSpecialityId(detailsData?.specialityId?._id);

    }
    if (consultationTypeList && consultationTypeList?.consultationType?.length === 1) {
      setValue("appointmentType", consultationTypeList.consultationType[0])
      setConsultType(consultationTypeList.consultationType[0]);
      setAppointmentPayload({ ...appointmentPayload, consultationType: consultationTypeList.consultationType[0] });
    }
  }, [consultationTypeList, practitionersList])

  useEffect(() => {
    if (specialityId) {
      setAppointmentPayload({
        ...appointmentPayload,
        specialityId: specialityId,
      });
    }
  }, [specialityId])
  useEffect(()=>{
    if(detailsData?.practitioner && appointmentPayload.state === "RESCHEDULE"){
      setValue("doctor", {id: detailsData?.practitioner._id ,name: `Dr.${detailsData?.practitioner?.name?.first} ${detailsData?.practitioner?.name?.last}`})
      setValue("specialities",detailsData?.specialityId)
      setSelectedDoctor({id: detailsData?.practitioner._id ,name: `Dr.${detailsData?.practitioner?.name?.first} ${detailsData?.practitioner?.name?.last}`});
    }
  },[detailsData])

  useEffect(() => {
    if (timeSlots) {
      const{Morning, AfterNoon, Evening, Night}=timeSlots || {}
      let slotMap: any = [...Morning, ...AfterNoon, ...Evening, ...Night];

      const timeConvertion =slotMap?.map((x:any)=>({
          slotId: x?.slotId,
          booked: x?.isBooked,
          isExpired : x?.isExpired, 
          time: moment(x?.time, "HH:mm").format("hh:mm a")
      }))

      setAppointmentPayload({ ...appointmentPayload, consultationFee: timeSlots?.Amount });
      setTimeSlotList(timeConvertion)
      setConsultationFee(timeSlots?.Amount)
    }
  }, [timeSlots]);

  const handleClose = () => {
    anchorEl && setAnchorEl(null);
  };

  const handlePolicyClose = () => {
    anchorEl2 && setAnchorEl2(null);
  }

  const renderPatients = (patients: IPatientDetail[], controlProps: ControllerRenderProps<Record<string, any>>) => {
    let _patients = patients.map((patient: IPatientDetail) => {
      let user: any = {
        patientDetail: patient,
        firstName: patient?.name?.first,
        lastName: patient?.name?.last,
        gender: patient?.gender || "male",
        mobileNumber: patient?.mobile || "",
        patientId: patient?._id,
      };
      return user;
    });
    return (
      <PatientListing
        id={"participant-List"}
        anchorEl={anchorEl}
        handleClose={handleClose}
        patientList={_patients || []}
        facilityUsersListFetching={facilityUsersListFetching}
        controlProps={controlProps}
        setSelectedPatient={setSelectedPatient}
        setIsDisabled={setIsDisabled}
        // setIsAddPatient={setIsAddPatient}
        appointmentPayload={appointmentPayload}
        setAppointmentPayload={setAppointmentPayload}
        tablePage={tablePage}
        setTablePage={setTablePage}
        hasNext={facilityUsersList?.hasNextPage}
      />
    );
  };
  // const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setChecked(e.target.checked);
  //   delete appointmentPayload.isFamily;
  //   delete appointmentPayload.familyMember;
  // };

  // const addNewPatient = async () => {
  //   const values = getValues(["firstName", "lastName", "gender", "phone"]);
  //   if (Object.values(values).every((value) => value)) {
  //     // setLoading(true);
  //     setRequest({ message: "", loading: false, severity: "" });
  //     if (values.phone) {
  //        const { countryCallingCode, nationalNumber }: any = parsePhoneNumber(values.phone);
  //       // const mobile = `${nationalNumber}`
  //       // const patient = await getPatientByPhoneApi(countryCallingCode, mobile)
  //       //   .then((res: any) => res)
  //       //   .catch((e: { response: any }) => e.response);
  //       // if (patient && patient?.status === 200) {
  //       //   setError("phone", { message: "This mobile number already exists" });
  //       //   setLoading(false);
  //       // } else {
  //         const payload = {
  //           countryCode: countryCallingCode,
  //           mobile: nationalNumber,
  //           name: {
  //             first: values.firstName,
  //             last: values.lastName,
  //           },
  //           gender: values.gender,
  //         };
  //         try {
  //           const createdPatient = await createPatientApi(payload);
  //           if (createdPatient && createdPatient.status === 201) {
  //             setRequest({ message: "Patient added successfully", loading: false, severity: "success" });
  //             // setLoading(false);
  //             setSelectedPatient(createdPatient.data);
  //             setValue("searchPatient", createdPatient.data.name.first + " " + createdPatient.data.name.last);
  //             setAppointmentPayload({ ...appointmentPayload, user: createdPatient.data._id, mobile: createdPatient.data?.mobile });
  //             setIsDisabled(false);
  //             // setIsAddPatient(false);
  //             if(createdPatient?.data?._id && createdPatient?.data?.mobile){
  //             try{await getPolicy(createdPatient?.data?._id,createdPatient?.data?.mobile)}
  //             catch(e){}
  //             }
  //           }
  //         } catch (error) {
  //           setRequest({
  //             message: error.response.data.message || "Something went wrong",
  //             loading: false,
  //             severity: "error",
  //           });
  //           // setLoading(false);
  //         }
  //      // }
  //     }
  //   } else {
  //     trigger();
  //   }
  // };
  // will be enabled in next phase
  // const getBalence = (consultationFee:any, walletBalance:any) => {
  //   return consultationFee > walletBalance ? consultationFee - walletBalance : 0;
  // } 

  // const getWalletAmount = (consultationFee:any, walletBalance:any) => {
  //   return walletBalance > consultationFee ? consultationFee : walletBalance
  // }

  return (
    <Grid container xs={12} className={classes.root} direction={"column"}>
      {bookingState === "NEW" && (
        <Grid item container xs={12} direction={"column"} className={classes.patientType}>
          {/* <Typography variant={"body2"} className={classes.title}>
            {"Patient Type"}
          </Typography> */}
          {/* <Grid item xs={12}>
            <Controller
              name={"patientType"}
              control={control}
              render={(controlProps) => {
                return (
                  <RadioGroup
                    row
                    name={"patientTypeChooser"}
                    onChange={(e) => {
                      controlProps.onChange(e.target.value);
                      setAppointmentPayload({ ...initialPayload, patientType: e.target.value });
                      setSelectedDate(null);
                      setValue("appointmentTime", "");
                      setValue("searchPatient", "")
                      setSelectedPatient(null);
                      setIsAddPatient(false);
                      setConsultType("")
                    }}
                  >
                    <Radio value={"policyHolder"} hidden id={"policyHolder"} />
                    <label
                      htmlFor={"policyHolder"}
                      className={clsx(classes.patientTypeChoice, appointmentPayload.patientType === "policyHolder" ? "active" : "")}
                    >
                      <Typography variant={"body2"} style={{fontSize: "13px"}}>{"Policy Holder"}</Typography>
                    </label>
                    <Radio value={"nonPolicyHolder"} hidden id={"nonPolicyHolder"} />
                    <label
                      htmlFor={"nonPolicyHolder"}
                      className={clsx(
                        classes.patientTypeChoice,
                        appointmentPayload.patientType === "nonPolicyHolder" ? "active" : ""
                      )}
                    >
                      <Typography variant={"body2"} style={{fontSize: "13px"}}>{"Non Policy Holder"}</Typography>
                    </label>
                  </RadioGroup>
                );
              }}
            />
          </Grid> */}
        </Grid>
      )}
      {/* {isAddPatient && (
        <Grid item container xs={12} className={clsx(classes.addPatientInformation, !isAddPatient ? "hidden" : "")}>
          <Grid item container xs={12} justifyContent="space-between">
            <Typography variant="body2" className={classes.title}>
              {"Add Patient"}
            </Typography>
          </Grid>
          <Grid item container xs={12} justifyContent="space-between" className={classes.createPatient}>
            <Grid item xs={6}>
              <Controller
                name="firstName"
                control={control}
                render={(controlProps) => {
                  return (
                    <CommonTextField
                      label="First Name"
                      value={controlProps.value}
                      onChange={(e: { target: { value: any } }) => {
                        controlProps.onChange(e.target.value);
                      }}
                    />
                  );
                }}
              />
              <CustomFormEM name={"firstName"} errors={errors} render={(message: string) => <p>{message}</p>} />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="lastName"
                control={control}
                render={(controlProps) => {
                  return (
                    <CommonTextField
                      label="Last Name"
                      value={controlProps.value}
                      onChange={(e: { target: { value: any } }) => {
                        controlProps.onChange(e.target.value);
                      }}
                    />
                  );
                }}
              />
              <CustomFormEM name={"lastName"} errors={errors} render={(message: string) => <p>{message}</p>} />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="phone"
                control={control}
                render={(controlProps) => {
                  return (
                    <CommonMobileInput
                      placeholder={"Mobile Number"}
                      displayInitialValueAsLocalNumber={true}
                      defaultCountry="IN"
                      value={controlProps.value}
                      onChange={(e: any) => {
                        controlProps.onChange(e);
                      }}
                    />
                  );
                }}
              />
              <CustomFormEM name={"phone"} errors={errors} render={(message: string) => <p>{message}</p>} />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="gender"
                control={control}
                render={(controlProps) => {
                  return (
                    <CommonDropDown
                      placeholder="Gender"
                      className={classes.dropDown}
                      onChange={(e: any) => {
                        controlProps.onChange(e.target.value);
                      }}
                      value={controlProps.value}
                    >
                      <CommonMenuItem value="male">Male</CommonMenuItem>
                      <CommonMenuItem value="female">Female</CommonMenuItem>
                      <CommonMenuItem value="other">Other</CommonMenuItem>
                    </CommonDropDown>
                  );
                }}
              />
              <CustomFormEM name={"gender"} errors={errors} render={(message: string) => <p>{message}</p>} />
            </Grid>
          </Grid>
          <Grid item container xs={12} direction={"row-reverse"}>
            <CustomSubmitButton className={"undefined"} onClick={addNewPatient} isLoading={loading}>
              {"Register New Patient"}
            </CustomSubmitButton>
            &nbsp;&nbsp;
            <CustomCancelButton
              onClick={() => {
                setIsAddPatient(false);
                reset({ firstName: "", lastName: "", gender: "", phone: "" });
              }}
            >
              {"Cancel"}
            </CustomCancelButton>
          </Grid>
        </Grid>
      )} */}
      {<Grid
        item
        container
        xs={12}
        justifyContent="space-between"
        style={{ marginBottom: '12px' }}
        className={/* appointmentPayload.patientType === "" || isAddPatient || */ bookingState !== "NEW" ? "hidden" : ""}
      >
        <Grid item container xs={12} className={classes.searchPatient}>
          <Grid item xs={6}>
            <Grid container xs={12} justifyContent="space-between">
              <Typography variant={"body2"} className={classes.title}>
                {appointmentPayload.patientType === "policyHolder" ? "Search Policy" : "Customer Information"}
              </Typography>

              {/* <Typography
                variant="body2"
                color="primary"
                className={classes.title}
                style={selectedPatient ? { opacity: '0.5' } : {}}
                onClick={() => {
                  if (!selectedPatient) {
                    setIsAddPatient(true);
                    setDisabled(true);
                  }
                }}
              >
                {"+ Add Patient"}
              </Typography> */}

            </Grid>
            <Controller
              name="searchPatient"
              control={control}
              render={(controlProps) => {
                return (
                  <>
                    <CommonTextFieldSearch
                      name={"patientSearch"}
                      placeholder={
                        appointmentPayload.patientType === "policyHolder"
                          ? "Search by policy or phone number"
                          : "Search by name or phone number"
                      }
                      disabled={isInstant}
                      inputProps={{
                        autoComplete: 'off'
                     }}
                      label={
                        appointmentPayload.patientType === "policyHolder" ? "Search Policy User" : "Search Customer"
                      }
                      variant="filled"
                      value={isInstant ? userData?.userName : controlProps.value}
                      onClick={() =>{
                        if(search !== ""){
                          setAnchorEl(document.getElementsByName("patientSearch")[0].parentElement);
                          refetchDetail();
                          setTablePage(1);
                        }
                      }}
                      onChange={(e: any) => {
                        controlProps.onChange(e);
                        setAnchorEl(document.getElementsByName("patientSearch")[0].parentElement);
                        setSearch(e.trim());
                        setTablePage(1);
                        if (e === "") {
                          setIsDisabled(true);
                          setSelectedPatient(null);
                          setValue('specialities', undefined)
                          setValue('doctor', undefined)
                          setValue("reasonForVisit", undefined)
                          setValue('appointmentDate', undefined)
                          setAppointmentPayload({ ...initialPayload , patientType: appointmentPayload.patientType});
                          setConsultType("")
                        }
                      }}
                    />
                    {search !== "" && !selectedPatient && renderPatients(patientsData, controlProps)}
                  </>
                );
              }}
            />
          </Grid>
          {selectedPatient && appointmentPayload.patientType === "policyHolder" && (
            <Grid item xs={6}>
              <Typography variant={"body2"} className={classes.title}>
                {"Policy & Patient Information"}
              </Typography>
              <Controller
                name={"policyInfo"}
                control={control}
                render={(controlProps) => {
                  return (
                    <>
                      <CommonTextField
                        name={"policyInfo"}
                        label={"Select Policy & Patient"}
                        placeholder={"Select Policy & Patient"}
                        onClick={(e: any) => {
                          setAnchorEl2(document.getElementsByName("policyInfo")[0].parentElement)
                        }}
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <IconButton
                              onClick={(e: any) =>
                                setAnchorEl2(document.getElementsByName("policyInfo")[0].parentElement)
                              }
                            >
                              {!anchorEl2 ? <ExpandMore /> : <ExpandLess />}
                            </IconButton>
                          ),
                        }}
                        value={controlProps.value || ""}
                      />
                      {selectedPatient && (
                        <PolicyListing
                          patientId={selectedPatient._id || ''}
                          policyLists={policydetail?.data || []}
                          policydetailfetching={policydetailfetching}
                          anchorEl={anchorEl2}
                          controlProps={controlProps}
                          handleClose={handleClose}
                          handlePolicyClose={handlePolicyClose}
                          appointmentPayload={appointmentPayload}
                          setAppointmentPayload={setAppointmentPayload}
                          setRequest={setRequest}
                          request={request}
                        />
                      )}
                    </>
                  );
                }}
              />
            </Grid>
          )}
          {(appointmentPayload.policyNumber !== "" && appointmentPayload.walletBalence) && (
              <Grid item container xs={12} className={classes.walletInformation} wrap="nowrap" alignItems="center">
                <Grid item>
                  <img src={Wallet} alt="" height={"24px"} />
                </Grid>
                <Grid
                  item
                  container
                  wrap="nowrap"
                  xs="auto"
                  alignItems="center"
                  justifyContent="space-between"
                  className="walletInfo"
                >
                  <Grid>
                    <Typography variant="body2">{"OPD Cashless Wallet Balance"}</Typography>
                    <Typography className="walletNote">
                      {"Note : Above wallet balance can be used for both proposer and dependents"}
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography variant="h6" className="walletBalance">
                      {`${appointmentPayload.walletBalence} ₹`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
        </Grid>
      </Grid>}

      {(appointmentPayload.policyNumber !== "" ||
        (appointmentPayload.patientType !== "policyHolder" && selectedPatient) ||
        bookingState !== "NEW" && bookingState !== "FOLLOW_UP") && (
          <Grid item container xl={12} className={classes.appointmentType}>
            <Typography className={classes.title}>{"Consultation Type"}</Typography>
            <Grid item xs={12}>
              <Controller
                name="appointmentType"
                control={control}
                render={(controlProps) => {
                  return (
                    <RadioGroup
                      row
                      name="ConsultTypeChoose"
                      style={{
                        justifyContent: `${consultationTypeFetching ? "center" : "start"}`,
                        paddingTop: `${consultationTypeFetching ? "32px" : "0px"}`
                      }}
                      onChange={(e) => {
                        controlProps.onChange(e.target.value);
                        setConsultType(e.target.value);
                        setSelectedDate(null);
                        setValue("appointmentTime", "");
                        setValue('specialities', "");
                        appointmentPayload?.isReferral && setValue('doctor', null);
                        setValue("reasonForVisit", undefined);
                        setValue('appointmentDate', undefined);
                        setAppointmentPayload({ ...appointmentPayload, consultationType: e.target.value ,scheduledAt: "", practitioner: userRole === USER_ROLES.doctor ? user._id : undefined});
                      }}
                    >
                      {consultationTypeList?.consultationType?.length > 0 && !consultationTypeFetching && 
                        <>
                          {consultationTypeList?.consultationType?.indexOf('VIRTUAL_CONSULTATION') !== -1 && <Radio
                            value={consultationType.virtualConsultation}
                            hidden
                            disabled={appointmentPayload.state === "RESCHEDULE" || appointmentPayload.state === "FOLLOWUP"}
                            id={consultationType.virtualConsultation}
                            //disabled={isDisabled}
                          />}
                          {consultationTypeList?.consultationType?.indexOf('VIRTUAL_CONSULTATION') !== -1 && <label
                            hidden={isDisabled}
                            htmlFor={consultationType.virtualConsultation}
                            className={clsx(
                              classes.appointmentTypeChoice,
                              consultType === consultationType.virtualConsultation ? "active" : ""
                            )}
                          >
                            <VirtualConsult />
                            <Typography variant="body2">{"Virtual Consult"}</Typography>
                          </label>}
                          {/* {consultationTypeList?.consultationType?.indexOf('PHYSICAL_CONSULTATION') !== -1 && <Radio
                            value={consultationType.physicalConsultation}
                            hidden
                            id={consultationType.physicalConsultation}
                            disabled={appointmentPayload.state === "RESCHEDULE" || appointmentPayload.state === "FOLLOWUP"}
                          />}
                          {consultationTypeList?.consultationType?.indexOf('PHYSICAL_CONSULTATION') !== -1 && <label
                            hidden={isDisabled}
                            htmlFor={consultationType.physicalConsultation}
                            className={clsx(
                              classes.appointmentTypeChoice,
                              consultType === consultationType.physicalConsultation ? "active" : ""
                            )}
                          >
                            <PhysicalConsult />
                            <Typography variant="body2">{"Physical Consultation"}</Typography>
                          </label>} */}
                        </>
                      }
                      {consultationTypeFetching && <CircularProgress />}
                    </RadioGroup>
                  );
                }}
              />
            </Grid>
          </Grid>
        )}
      {appointmentPayload.consultationType !== "" && (
        <Grid item container xl={12} className={classes.consultInformation}>
          <Grid xs={12}>
            {bookingState !== "FOLLOW_UP" &&<Typography className={classes.title}>{"Consult Information"}</Typography>}
          </Grid>
          {
            (isInstant  && userRole === USER_ROLES.doctor) && 
            <div className={classes.rerferContainer}>
            <div className={isReferInternal? classes.referInternal : ""}>
           <CustomCheckboxLabel label={"Refer Internally"}
            checked={appointmentPayload?.isReferral}
             onChange={()=>{
              if(!isReferInternal)
              setAppointmentPayload({...appointmentPayload,practitioner:'',isReferral:!appointmentPayload?.isReferral})
            }}/>
            </div>  
            {isReferInternal && <p style={{marginBottom:0,opacity:"0.8"}}>Refer Internally can be done only once per consultation </p>} 
            </div>     
          }
          <>
            <Grid item container xs={12} style={{ gap: '12px' }}>
              {(appointmentPayload.state !== "FOLLOWUP") && <Grid item xs={6}>
                <Controller
                  name="specialities"
                  control={control}
                  render={(controlProps) => (
                    <CommonSearchMultiselect
                      key={appointmentPayload.consultationType}
                      disabled={isDisabled}
                      options={newSpeciality || []}
                      loading={specialitylistFetching}
                      className={classes.specialitiesTypes}
                      variant="filled"
                      getOptionLabel={(option: any) => option?.name}
                      label={"Select Specialty"}
                      value={controlProps.value}
                      onChange={(e: any, value: any) => {
                        if (value === null) {
                          controlProps.onChange(value);
                          setSpecialityId('');
                          setAppointmentPayload({
                            ...appointmentPayload,
                            specialityId: '',
                          });
                          setSpecialitiesPage(1)
                          selectedDoctor(null)
                        }
                        else {
                          controlProps.onChange(value);
                          setSpecialityId(value?.id);
                          setAppointmentPayload({
                            ...appointmentPayload,
                            specialityId: value?.id,
                          });
                          setSpecialitiesPage(1)
                        }
                      }}
                      ListboxProps={{
                        style: { maxHeight: '300px' },
                        onScroll: (event) => {
                          const listboxNode = event.currentTarget;
                          if (listboxNode.scrollHeight - listboxNode.scrollTop - listboxNode.clientHeight < 1) {
                            setSpecialitiesPage(specialitiesPage + 1)
                          }
                        }
                      }}
                      onInputChange={(event, newInputValue) => {
                        setsearchSpeciality(newInputValue);
                        !newInputValue && setSpecialitiesPage(1);
                      }}
                    />
                  )}
                />
              </Grid>}
              {(userRole !== "UNIFIED_APP_DOCTOR" || appointmentPayload?.state === "RESCHEDULE" || userRole === USER_ROLES.receptionist || isInstant) &&
                <Grid item xs={6}>
                  <Controller
                    name={"doctor"}
                    control={control}
                    render={(controlProps) => {
                      return (
                          <CommonSearchMultiselect
                            key={appointmentPayload.consultationType}
                            className={classes.specialitiesTypes}
                            options={practitionersList || []}
                            loading={searchingDoctor}
                            getOptionLabel={(option: any) => option?.name ? option?.name : option}
                            disabled={!appointmentPayload?.isReferral && userRole === USER_ROLES?.doctor}
                            value={controlProps?.value}
                            variant="filled"
                            label={"Select Doctor"}
                            onChange={(e: any, value: any) => {
                              if (value === null) {
                                controlProps.onChange('');
                                setSelectedDoctor(null);
                                setAppointmentPayload({
                                  ...appointmentPayload,
                                  practitioner: '',
                                });
                                setTimeSlotList([])
                                setDoctorPage(1)
                              }
                              else {
                                controlProps.onChange(value);
                                setSelectedDoctor(value);
                                setAppointmentPayload({
                                  ...appointmentPayload,
                                  practitioner: value?.id,
                                });
                              }
                            }}
                            ListboxProps={{
                              style: { maxHeight: '300px' },
                              onScroll: (event) => {
                                const listboxNode = event.currentTarget;
                                if (listboxNode.scrollHeight - listboxNode.scrollTop - listboxNode.clientHeight < 1) {
                                  setDoctorPage(DoctorPage + 1)
                                }
                              }
                            }}
                            onInputChange={(event, newInputValue) => {
                              setPractitionersList([])
                              setsearchdoctor(newInputValue);
                              setDoctorPage(1);
                            }}
                            filterOptions={(option)=>{
                              if(isInstant && appointmentPayload?.isReferral)
                              return option?.filter((item:any)=>item?.id !== user?._id)
                              else
                              return option
                            }}
                          />

                      )
                    }}
                  />
                </Grid>}
            </Grid>
          </>
        {bookingState !== "FOLLOW_UP" && <Grid item xs={6}>
            <Controller
              name={
                bookingState === "NEW"
                  ? "reasonForVisit"
                  : bookingState === "FOLLOW_UP"
                    ? "followupReason"
                    : "rescheduleReason"
              }
              control={control}
              render={(controlProps) => {
                if ((appointmentPayload.state === "RESCHEDULE")&&!controlProps.value){
                      controlProps.onChange(detailsData?.reason?.id)
                }
                return (
                  <CommonDropDown
                    className={controlProps.value ? classes.dropDownvalue : classes.dropDown}
                    label={
                      bookingState === "NEW"
                        ? "Reason for Visit"
                        : bookingState === "FOLLOW_UP"
                          ? "Reason for Followup"
                          : "Reason for Reschedule"
                    }
                    value={controlProps.value}
                    variant="filled"
                    disabled={isDisabled}
                    onChange={(e: { target: { value: any } }) => {
                      controlProps.onChange(e?.target?.value);
                      delete appointmentPayload.otherReason;
                      appointmentPayload?.state === "RESCHEDULE" ? setAppointmentPayload({ ...appointmentPayload, reason: e?.target?.value }) : setAppointmentPayload({ ...appointmentPayload, reason: [e?.target?.value] });
                    }}
                  >
                    {bookingState === "NEW" ? (consultationReason?.data?.length > 0 ? (
                      consultationReason?.data?.map((reason: any) => {
                        return <CommonMenuItem value={reason.id}>{reason.name}</CommonMenuItem>;
                      })
                    ) : (
                      <CommonMenuItem disabled value={"na"}>
                        { consultationReasonFetching
                            ? "Loading..."
                            : "No Data Available"}
                      </CommonMenuItem>
                    )) :
                      Reasons?.map((reason: any) => {
                        return <CommonMenuItem value={reason.id}>{reason.name}</CommonMenuItem>;
                      })}
                  </CommonDropDown>
                )
              }}
            />
            <CustomFormEM name={ bookingState === "NEW" ? "reasonForVisit" : bookingState === "FOLLOW_UP"? "followupReason": "rescheduleReason"} errors={errors} render={(message: string) => <p>{message}</p>} />
          </Grid>}
          {(appointmentPayload?.reason === "640ed1da1ef04d3bcf283447" ||
            appointmentPayload?.reason === "640ed0711ef04d3bcf283439" || appointmentPayload?.reason === "640ed1361ef04d3bcf28343f" || 
            appointmentPayload?.reason === "640ed1741ef04d3bcf283440") && bookingState !== "FOLLOW_UP" && (
            <Grid item xs={6}>
              <Controller
                name="otherReason"
                control={control}
                render={(controlProps) => {
                  if(detailsData?.otherReason && !controlProps.value){
                    controlProps.onChange(detailsData?.otherReason);
                  } 
                  return (
                    <CommonTextField
                      label={"Other Reason"}
                      placeholder="Other Reason"
                      value={controlProps.value}
                      onChange={(e: any) => {
                        setAppointmentPayload({ ...appointmentPayload, otherReason: e.target.value });
                        controlProps.onChange(e.target.value);
                      }}
                    />
                  );
                }}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <Controller
              name="appointmentDate"
              control={control}
              render={(controlProps) => {
                return (
                  <CommonDatePicker
                    label={"Select Date"}
                    format="DD/MM/YYYY"
                    placeholder="Select Date"
                    className={classes.dates}
                    value={controlProps.value || null}
                    disablePast
                    inputProps={{ readOnly: true }}
                    maxDate={moment(moment(new Date()).add(30, 'd').format('YYYY/MM/DD HH:mm:ss'))}
                    disabled={isDisabled}
                    onChange={(e: any) => {
                      setSelectedDate(e);
                      setAppointmentPayload({ ...appointmentPayload, scheduledAt: "" });
                      setValue("appointmentTime", "");
                      controlProps.onChange(e);
                    }}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
      )}
      {selectedDate && appointmentPayload.consultationType !== "" && (
        <Grid item container xs={12} className={classes.availableTime}>
          <Typography className={classes.title}>{"Available Time Slots"}</Typography>
          <Grid item container xs={12}>
            <Controller
              name={"appointmentTime"}
              control={control}
              render={(controlProps) => {
                return (
                  <RadioGroup
                    style={{display: 'flex', justifyContent: `${timeSlotFetching ? 'center' : 'start'}`, width: '100%', paddingTop: `${timeSlotFetching ? "32px" : "0px"}`}}
                    row
                    name="appointmentTime"
                    onChange={(e) => {
                      controlProps.onChange(e.target.value);
                      const splitValue=moment(e.target.value, "hh:mm a").format("HH:mm").split(":")
                      const{Morning, AfterNoon, Evening, Night}=timeSlots || {}
                      let slotMap: any = [...Morning, ...AfterNoon, ...Evening, ...Night];
                      const slotTime = slotMap?.map((time:any)=>(time?.time))
                      Object.entries(slotTime).forEach(([key, value]: any, index: any) => {
                        if (slotTime.indexOf(moment(e.target.value, "hh:mm a").format("HH:mm")) !== -1) {
                          const selectedTime = slotMap.find((time: any) => moment(time?.time, "HH:mm").format("HH:mm") === moment(e.target.value, "hh:mm a").format("HH:mm"));
                          setAppointmentPayload({
                            ...appointmentPayload,
                            slotId: selectedTime?.slotId,
                            slotTime: (+splitValue[0] * (60000 * 60)) + (+splitValue[1] * 60000),
                            scheduledAt: moment(selectedDate).format("YYYY/MM/DD")
                          });
                        }
                      })
                    }}
                    value={controlProps.value}
                  >
                    {timeSlotFetching && <CircularProgress />}
                    {timeSlotList &&
                      !timeSlotFetching &&
                      timeSlotList?.map((key: any) => {
                        return (
                          <>
                            <Radio value={key?.time} hidden id={key?.time} disabled={isDisabled || key?.isExpired || key?.booked} />
                            <label
                              htmlFor={key?.time}
                              className={key?.isExpired || key?.booked ? clsx(classes.timeSlots,classes.TimeHide) : clsx(classes.timeSlots, controlProps.value === key?.time ? "active" : "")}
                            >
                              <Typography variant="body2">{key?.time}</Typography>
                            </label>
                          </>
                        );
                      })}
                  </RadioGroup>
                );
              }}
            />
             {!timeSlotFetching && timeSlotList?.filter((x:any)=> x?.booked === false).length === 0 &&
              <Typography className={classes.noSlots}>No slots available</Typography>
             }
          </Grid>
        </Grid>
      )}
      {/* {appointmentPayload.scheduledAt !== "" && bookingState !== "reschedule" && (
        <>
          <Grid item container xs={12} className={classes.paymentInformation}>
            <Typography className={classes.title}>{"Payment Information"}</Typography>
            <Grid item container xs={12} className={"infoGrid"}>
              {appointmentPayload.patientType === "policyHolder" && (
                <Grid item xs={12}>
                  <Controller
                    name={"opdWalletBalance"}
                    control={control}
                    render={(controlProps) => {
                      return (
                        <>
                          <Checkbox
                            id="opdWalletBalance"
                            disableRipple
                            checked={checked}
                            onChange={(e:any)=>{
                              handleCheck(e)
                            }}
                            color="primary"
                          />
                          <label htmlFor="opdWalletBalance">{"Use OPD Wallet Balance"}</label>
                        </>
                      );
                    }}
                  />
                </Grid>
              )}
              <Grid item container xs={12} justifyContent={"space-between"} wrap={"nowrap"}>
                <Typography variant={"body2"}>{"Consultation Fee"}</Typography>
                <Typography variant={"body2"} align={"right"}>
                  {`${timeSlots?.Amount} ₹`}
                </Typography>
              </Grid>
              <Grid item container xs={12} justifyContent={"space-between"} wrap={"nowrap"}>
                <Typography variant={"body2"}>{appointmentPayload.patientType === "nonPolicyHolder" ? "Other Charges" : "Paid from Star Cashless OPD"}</Typography>
                <Typography variant={"body2"} align={"right"}>
                  {`-  ${appointmentPayload.patientType === "nonPolicyHolder" ? 0 : 
                          checked && appointmentPayload?.walletBalence ? getWalletAmount(timeSlots?.Amount, appointmentPayload?.walletBalence) : 0}  ₹`}
                </Typography>
              </Grid>
              <Grid item container xs={12} justifyContent={"space-between"} wrap={"nowrap"}>
                <Typography variant={"body2"} style={{ fontWeight: 600 }}>
                  {"Total Amount to Pay"}
                </Typography>
                <Typography variant={"body2"} align={"right"} style={{ fontWeight: 600 }}>
                {`${ appointmentPayload.patientType === "nonPolicyHolder" ? timeSlots?.Amount : 
                       checked && appointmentPayload?.walletBalence ? getBalence(timeSlots?.Amount, appointmentPayload?.walletBalence) : timeSlots?.Amount} ₹`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            alignItems={"center"}
            style={{ padding: "12px 16px", backgroundColor: "#F3F7FF", borderRadius: "6px", marginTop: '12px' }}
          >
            <Info color="primary" />
            &nbsp;&nbsp;
            <Typography variant={"body2"} style={{ fontSize: "11px", color: "#64707D" }}>
              {"Note : Consultation fee have to be paid at hospital after consultation"}
            </Typography>
          </Grid>
        </>
      )} */}
    </Grid>
  );
};

export default BookAppointment;
