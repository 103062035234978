import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import useRoomState from '../../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { Typography, Grid } from '@material-ui/core';
import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../Buttons/ToggleVideoButton/ToggleVideoButton';
import VideoCollapse from '../Buttons/VideoCollapse';
import AddUser from '../../../assets/images/wpf_add-user.png';
import { useSelector } from 'react-redux';
import { USER_ROLES } from '../../../utils/constants';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      left: 0,
      right: 0,
      height: `72px`,
      position: 'absolute',
      display: 'flex',
      zIndex: 10,
      [theme.breakpoints.down('sm')]: {
        height: `56px`,
        padding: 0,
      },
    },
    screenShareBanner: {
      position: 'fixed',
      zIndex: 8,
      bottom: `72px`,
      left: 0,
      right: 0,
      height: '104px',
      background: 'rgba(0, 0, 0, 0.5)',
      '& h6': {
        color: 'white',
      },
      '& button': {
        background: 'white',
        color: '#E22525',
        border: `2px solid '#E22525'`,
        margin: '0 2em',
        '&:hover': {
          color: '#600101',
          border: `2px solid #600101`,
          background: '#FFE9E7',
        },
      },
    },
    hideMobile: {
      display: 'initial',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    Image:{
      background:'#485880',
      minWidth: '74px',
      '&:hover': {
        background:'#485880'
      },
      // '&:active':{
      //   background: '#091021',
      // }
    },
    ButtonCollapse:{
      "& .MuiButton-startIcon":{
        height:"2.5rem",
        width:"2.5rem",
      }
    }
  })
);

export default function MenuBar({ reference, isVideoCollapsed ,OpenDialog,setRoomStatus}: any) {
  const classes = useStyles();
  const location = useLocation()
  const { isSharingScreen, toggleScreenShare } = useVideoContext();
  const roomState = useRoomState();
  const { userRole } = useSelector((state : any) => state?.auth);
  const isReconnecting = roomState === 'reconnecting';
  const { instantdata, connecting } = useSelector((state : any)=> state?.instantVideo)
  const {consultType}: any = location?.state || {}

  return (
    <>
      {isSharingScreen && (
        <Grid container justifyContent="center" alignItems="center" className={classes.screenShareBanner}>
          <Typography variant="h6">You are sharing your screen</Typography>
          <Button onClick={() => toggleScreenShare()}>Stop Sharing</Button>
        </Grid>
      )}
      <footer
        className={classes.container}
        style={{ background: isVideoCollapsed ? 'transparent' : '#000000', bottom: isVideoCollapsed ? '20px' : 0 }}
      >
        <Grid container justifyContent="space-around" alignItems="center">
          {!isVideoCollapsed && (
            //<Hidden smDown>
              <Grid style={{ flex: 1 }}>{/* <Typography variant="body1">{room!.name}</Typography> */}</Grid>
            //</Hidden>
          )}
          <Grid item>
            <Grid id={'end-call-buttons'} container justifyContent="center">
              <ToggleVideoButton className={isVideoCollapsed ? classes.ButtonCollapse : ''} disabled={isReconnecting} />
              <EndCallButton className={isVideoCollapsed ? classes.ButtonCollapse : ''} reference={reference} isVideoCollapsed={isVideoCollapsed} setRoomStatus={setRoomStatus}/>
              <ToggleAudioButton className={isVideoCollapsed ? classes.ButtonCollapse : ''} disabled={isReconnecting} />
            </Grid>
          </Grid>
          {!isVideoCollapsed && (
            //<Hidden smDown>
            <>
              <Grid style={{ flex: 1, height: '100%' }}>
                <Grid container justifyContent="flex-end"  style={{ height: '100%' }}>
                {/* <CustomSubmitButton className={''} onClick={()=>{OpenDialog(true);}}>
                    Add Doctor
                  </CustomSubmitButton> */}
                  {(userRole !== USER_ROLES.doctor && !instantdata?.doctorConnected && location.pathname.includes('/InstantCall/room')) && !connecting.show && consultType !== 'VIRTUAL_CONSULTATION' && <Button className ={classes.Image} onClick={()=>{OpenDialog();}}>
                  <img src={AddUser}/>
                  </Button> }
                  <VideoCollapse reference={reference} />
                </Grid>
              </Grid>
              </>
            //</Hidden>
          )}
        </Grid>
      </footer>
    </>
  );
}
