import React, { useState, useEffect } from "react";
import Offline from "../../components/Offline";
import Header from "../../components/Header";
import { worker } from "../../utils/networkDetector";
import LowLatency from "../../components/lowLatency";

export default function Layout({ isAuthenticated, children }) {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isDataOn, setIsDataOn] = useState(true);

  worker.onmessage = (res) => {
   setIsDataOn(res.data)
  };

  if(!isDataOn){
    worker.terminate()
  }
  
  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener("online", handleStatusChange);

    // Listen to the offline status
    window.addEventListener("offline", handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);

  return (isOnline && isDataOn) ? (
    isAuthenticated ? (
      <>
        <Header>{children}</Header>
        <LowLatency/>
      </>
     
    ) : (
      children
    )
  ) : (
    <Offline />
  );
}
