export const AUTH_USER = "AUTH_USER";
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_CHECK_TIMEOUT = "AUTH_CHECK_TIMEOUT";
export const AUTH_INITIATE_LOGOUT = "AUTH_INITIATE_LOGOUT";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_TOKEN = "AUTH_TOKEN";
export const AUTH_COMPLETED = "AUTH_COMPLETED";
export const SWITCH_USER_ROLE = "SWITCH_USER_ROLE";
export const UPDATE_USER_MOBILE = "UPDATE_USER_MOBILE";
export const DB_DATA = "DB_DATA";
export const VENDOR_DETAILS = "VENDOR_DETAILS";
export const REFER_INTERNAL = "REFER_INTERNAL"


export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_START = "REGISTER_START";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const REGISTER_MESSAGE = "REGISTER_MESSAGE";
export const REGISTER_OTP = "REGISTER_OTP";
export const REGISTER_OTP_SUCCESS = "REGISTER_OTP_SUCCESS";
export const REGISTER_OTP_ONE_TIME_TOKEN = "REGISTER_OTP_ONE_TIME_TOKEN";

export const CLINIC_DETAILS = "CLINIC_DETAILS";
export const UPDATE_CLINIC_INFO = "UPDATE_CLINIC_INFO";
export const USER_DETAILS = "USER_DETAILS";

export const UPDATE_CARE_PROGRAM_DATA = 'UPDATE_CARE_PROGRAM_DATA'
export const CLEAR_CARE_PROGRAM_DATA = 'CLEAR_CARE_PROGRAM_DATA'

export const OPEN_WEBSOCKET = 'OPEN_WEBSOCKET'
export const CHAT_ROOM_LIST = 'CHAT_ROOM_LIST'
export const CLOSE_WEBSOCKET = 'CLOSE_WEBSOCKET'
export const CHAT_ROOM_INFO = 'CHAT_ROOM_INFO'
export const CHAT_ROOM_PARTICIPANT_INFO = 'CHAT_ROOM_PARTICIPANT_INFO'
export const CHAT_MESSAGE_LIST = 'CHAT_MESSAGE_LIST'
export const CHAT_OTHER_INFO = 'CHAT_OTHER_INFO'
export const OPEN_CHAT_LIST = 'OPEN_CHAT_LIST'
export const ENABLE_CHAT_WIDGET = 'ENABLE_CHAT_WIDGET'
export const OPEN_CHAT_DETAILS = 'OPEN_CHAT_DETAILS'
export const OPEN_CHAT_ROOMS = 'OPEN_CHAT_ROOMS'
export const FACILITY_LIST  = 'FACILITY_LIST'
export const UPDATE_USER_DETAILS ='USER_DETAILS'
export const CALL_PICK_UP  = 'CALL_PICK_UP'
export const CHAT_NOW  = 'CHAT_NOW'
export const UPDATE_INST_INFO ='UPDATE_INST_INFO'
export const UPDATE_BOOK_ID ='UPDATE_BOOK_ID'
export const UPDATE_CALL_INFO='UPDATE_CALL_INFO'
export const UPDATE_DOCTOR_INFO='UPDATE_DOCTOR_INFO'
export const FACILITY_DETAILS='FACILITY_DETAILS'
export const UPDATE_CONNECTING_INFO ='UPDATE_CONNECTING_INFO'
export const UPDATE_MESSAGE_LIST = 'UPDATE_MESSAGE_LIST'
export const EMPTY_MESSAGE_LIST = 'EMPTY_MESSAGE_LIST'
export const REPLACE_MESSAGE_LIST = 'REPLACE_MESSAGE_LIST'
export const INSTANT_CHAT_CONNECTED = 'INSTANT_CHAT_CONNECTED'
export const INSTANT_CHAT_DISCONNECTED = 'INSTANT_CHAT_DISCONNECTED'
export const INSTANT_CHAT_RECONNECT = 'INSTANT_CHAT_RECONNECT'
export const INSTANT_CHAT_DETAILS = 'INSTANT_CHAT_DETAILS'
export const INSTANT_CHAT_SLOTID = 'INSTANT_CHAT_SLOTID'
export const ON_CHAT_BUSY ='ON_CHAT_BUSY' 
export const SKIP_COUNT = 'SKIP_COUNT'
export const NEW_MSG_RECEIVED = 'NEW_MSG_RECEIVED'
export const SHOW_CONSULTATION = 'SHOW_CONSULTATION'
export const UPD_NOTIFY_LIST = 'UPD_NOTIFY_LIST'
export const EMP_NOTIFY_LIST = 'EMP_NOTIFY_LIST'
export const NEW_NOTIFY_UPDATE = 'NEW_NOTIFY_UPDATE'
export const INSTANT_VIDEO_TYPE = 'INSTANT_VIDEO_TYPE'
export const DISCONNECT_MSG = 'DISCONNECT_MSG'
export const UPDATE_CALL_ID ='UPDATE_CALL_ID'
export const UPDATE_CALLBACK_INFO ='UPDATE_CALLBACK_INFO'
export const ROOM_STATUS = 'ROOM_STATUS'
export const UPDATE_IS_REJOIN = 'UPDATE_IS_REJOIN'
export const EMPTY_INSTANT_VIDEO = 'EMPTY_INSTANT_VIDEO'
export const ACTIVE_CONSULTATION_DUPLICATE_TAB = 'ACTIVE_CONSULTATION_DUPLICATE_TAB'
export const USER_ONLINE_STATUS = 'USER_ONLINE_STATUS'
export const DOCTOR_TYPING = 'DOCTOR_TYPING'
export const INITIAL_WEBSOCKET_CREATION = 'INITIAL_WEBSOCKET_CREATION'
export const TWILLIO_ERROR_CODE = 'TWILLIO_ERROR_CODE'
export const LOW_LATENCY = 'LOW_LATENCY'
export const REMOVE_RECEPTIONIST = 'REMOVE_RECEPTIONIST'
export const UPDATE_CAMERA_FLIP = 'UPDATE_CAMERA_FLIP'
export const UPDATE_PATIENT_INFO = 'UPDATE_PATIENT_INFO'
export const CHAT_TO_CALL_REQUEST = 'CHAT_TO_CALL_REQUEST'
export const USER_END_STATUS = 'USER_END_STATUS'
