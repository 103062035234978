import { CircularProgress, IconButton, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { uploadToS3 } from "../../../../utils/uploadToS3";
import { styles } from "./styles";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useSelector, useDispatch } from "react-redux";
import { FILE_SIZE } from "../../../../utils/constants";
import { Tooltip, Popover, Typography } from "@mui/material";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {
  sendMsg
} from "../../../../components/Websocket/InstantChatIO";
import { UpdateChatToCallRequest } from "../../../../store/actions";

const useStyles = makeStyles(styles);
const FileUploadButton = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const dispatch = useDispatch();
  const { dbData, user } = useSelector((state) => state?.auth);
  const { chatToCallRequest } = useSelector((state) => state.instantVideo);
  const { userEnd } = useSelector((state) => state?.instantChat);

 
  const bytesToMegaBytes = (bytes) => bytes / (1024 * 1024);
   
  const triggerFileSelect = () => {
    if(props?.fileCount?.current < 5)
    {
      document.getElementById('file-input').click();
    }
  };

  const handleFileUpload = async (e) => {
    if ( e.target.files.length < 6) {
      props.popMessage({
        loading: true,
        message: "",
        severity: "",
      });
      props.setTypeMessage("");
      setLoading(true);
      const regex = /[~#%\&_{}+\|]|\\.\\.|^\\.|\\.$/;
      {
        const type = [
          "application/pdf",
          "image/jpeg",
          "image/png",
          ".csv",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-excel",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ];

        let validType = 1;
        let invalidCharlength = false ;
        const filesList =  e.target.files ;
        for ( let i =0 ; i < filesList.length ;i++)
        {
          if(!type.includes(filesList[i].type ))
          {
            validType = 0 ;
            break ;
          }
          else
          {
            const nameCharlength = filesList[i].name.split('.').slice(0,-1).join('.');
            if(nameCharlength.length > 100)
            {
              validType = 0 ;
              invalidCharlength = true ;
              break ;
            }
          }
        }

        if (validType === 1) {
          let isValidCount = 0;

          for (let i=0 ;i < filesList.length ;i++)
          {
            const fileSize = bytesToMegaBytes(filesList[i].size);
            const fileSizeVal = FILE_SIZE.maxFileSize / (1024*1024)
            if (fileSize < fileSizeVal) {
              isValidCount++;
            } else {
              setTimeout(() => {
                props.popMessage({
                  loading: false,
                  message: "File size more than 5 MB is not supported",
                  severity: "",
                });
              },100)
            }
          }
          const urlPath ={url:[],keypath:[]}
          if (e.target.files.length === isValidCount) {
                if(filesList.length + props.fileCount.current < 6){
                 let array = [] ;
                  for (let i=0 ;i < filesList.length ;i++)
                  {
                    try
                    {
                      const {url , keyPath} =  await uploadFilesToSErver(e.target.files[i]);
                      urlPath.url[i] = url;
                      urlPath.keypath[i] = keyPath;
                    }
                    catch (error)
                    {
                      setTimeout(() => {
                        props.popMessage({
                          loading: false,
                          message:error?.message || "Error Occured while uploading!",
                          severity: "error",
                        });
                      },100)
                      setLoading(false);
                      break;
                    }
                   
                  }
                }else{
                  setTimeout(() => {
                    props.popMessage({
                      loading: false,
                      message:`Already sent ${props.fileCount.current} files. Maximum file limit is 5.`,
                      severity: "",
                    });
                  },100)
                  
              }
            props?.setUrlpath(urlPath);
          }
        }
        else
        {
          setTimeout(() => {
            props.popMessage({
              loading: false,
              message: invalidCharlength ? "File name is too long" :"Upload a valid File type",
              severity: "error",
            });
          },100)
        }
      }
    } else {
      props.popMessage({
        loading: true,
        message: "",
        severity: "",
      });
      setTimeout(() => {
        props.popMessage({
          loading: false,
          message: "Upload Maximum only 5 files",
          severity: "",
        });
      },100)
    }
    setAnchorEl(null);
    setLoading(false);
  };
  const uploadFilesToSErver = async (files) => {
    const file = files;
    const { url, err, keyPath } = await uploadToS3(file, "CHAT", {
      key: "user_id",
      value:props?.user_id,
    });
    if (url) {
      setTimeout(() => {
        props.popMessage({
          loading: false,
          message: "Uploaded successfuly",
          severity: "success",
        });
      },100)
      return {url , keyPath}
    } else {
     const error = new Error();
     error.message = err?.response?.data?.message;
     throw error;
    }
  };
  const handleFileClick = (event) => {
      event.target.value = "";
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      document.getElementById("attach-file").blur();
      props.handleKeyDown(e);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRequestIntantCall = () => {
    if(!checkCallRequestDisable()){
      sendMsg("sendMessage", {
        userId: user?._id,
        room: props?.roomId,
        isMultimedia : false,
        type : 'InstantCallRequest'
      });
      dispatch(UpdateChatToCallRequest({status: "InstantCallRequest", reqCount: parseInt(chatToCallRequest?.reqCount).toString() !== 'NaN' ? parseInt(chatToCallRequest?.reqCount)+1 : 1}))
    }
  }

  const checkCallRequestDisable = () => {
    if((chatToCallRequest && ((parseInt(chatToCallRequest?.reqCount).toString() !== 'NaN' && parseInt(chatToCallRequest?.reqCount) > 1) ||
    (chatToCallRequest?.status === "RequestAccepted"))) || userEnd){
      return true
    } 
    return false 
  }

  return(
    <>
      <IconButton className={classes.root} onClick={handleClick}>
        {!anchorEl ? <AddCircleIcon style={{ marginRight: "5px", marginTop: "5px", color: "deepskyblue" }} /> :
        <RemoveCircleIcon style={{ marginRight: "5px", marginTop: "5px", color: "deepskyblue" }} />}
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        >
          <Tooltip placement={"left-start"}title={props?.fileCount?.current >= 5 ? "Maximum File Upload Count 5 exceeded" : ''} slotProps={{tooltip : {style:{color : "red",background :"bisque",borderRadius :"0.5rem"}},arrow:{style:{color:"bisque"}}}} arrow={true} >
            <Typography id="attach-file" className={classes.root} onClick={triggerFileSelect} onKeyDown={handleKeyDown}>
              {loading &&
                <CircularProgress style={{ height: "24px", width: "24px" }} />
              }
              
              {!loading &&
                props.chatParticipantInfo.isGroup && !props.chatParticipantInfo.isActive 
                ? null :  "Add Document"}

              <input disabled ={props.connected}
                type="file" id="file-input" accept="application/pdf,image/jpeg,image/png,.csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={handleFileUpload} onClick={handleFileClick} multiple/>
            </Typography>
          </Tooltip>
          <Tooltip 
            placement={"left-start"} 
            title={(chatToCallRequest && (parseInt(chatToCallRequest?.reqCount).toString() !== 'NaN' && parseInt(chatToCallRequest?.reqCount) > 1 && chatToCallRequest?.status === "InstantCallRequest")) 
              ? "Your Instant Call request is already sent." : 
              (chatToCallRequest && chatToCallRequest?.status === "RequestAccepted") 
              ? "Customer has already accepted your request." : 
              userEnd ? "Patient Ended the chat." : ""} 
            slotProps={{tooltip : {style:{color : "#000000",background :"#F4F7FB",borderRadius :"0.5rem"}},arrow:{style:{color:"#F4F7FB"}}}} 
            arrow={true}>
            <Typography className={classes.root} sx={{ opacity: checkCallRequestDisable()? '0.5' : '1' }} onClick={handleRequestIntantCall}>Request Instant Call</Typography>
          </Tooltip>
      </Popover>
    </>
  );
};

export default FileUploadButton;