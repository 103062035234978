
  export const PRESCRIPTION_SERVICE_BASEURL =
  process.env.REACT_APP_API_BASE_URL + "consultation-service/api/v1/prescriptions/autocomplete/medicines/brands";
// Facility URLs
export const FACILTY_SERVICE_BASEURL = process.env.REACT_APP_API_BASE_URL + "dev/facility-service/api/v1";
export const CONSULTATION_BASEURL = process.env.REACT_APP_API_BASE_URL + "dev/consultation-service/api/v1";
export const AVAILABLE_TIMESLOT_BASEURL = process.env.REACT_APP_API_BASE_URL + "dev/slot-service/api/v1/availableTimeSlot";
//country,state,city
export const COUNTRY_STATE_CITY_BASEURL = `${process.env.REACT_APP_API_BASE_URL}master-data-service/api/v1`;
// patient create
export const BASE_URL_OPD = process.env.REACT_APP_API_BASE_URL + "/user-service/api/v1/users"

export const TERMS_OF_USE_URL =
  "https://www.starhealth.in/blog/wp-content/uploads/2021/10/Wellnessprogram-Terms-of-use.pdf";
export const PRIVACY_POLICY_URL = "https://securra.com/privacy-policy";
// Dashboard
export const DASHBOARD_SERVICE = process.env.REACT_APP_API_BASE_URL + 'dashboard-service/api/v1'
//Consultation
export const CREATE_CONSULTATION = process.env.REACT_APP_API_BASE_URL + 'prescription/v1/Prescription';
export const CREATE_MEDICAL_HISTORY = process.env.REACT_APP_API_BASE_URL + 'dataparameter/v1';
export const CREATE_CASESHEET=process.env.REACT_APP_API_BASE_URL +"prescription/v1/";
//Transcriber 
export const TRANSCRIBER_URL = process.env.REACT_APP_API_BASE_URL+'labpartner/v1/OPD';
export const LABPARTNER_URL = process.env.REACT_APP_API_BASE_URL+'labpartner/v1/lab';

// Slot Management
const SLOT_MANAGEMENT_BASEURL = process.env.REACT_APP_API_BASE_URL
const SLOT_FACILITIES_BASEURL = `${SLOT_MANAGEMENT_BASEURL}facility-service/api/v1/all/`
const SLOT_SERVICE_API = `${SLOT_MANAGEMENT_BASEURL}slot-service/api/v1/`

export const CREATE_SLOT = `${SLOT_SERVICE_API}creator`
export const GET_ALL_FACILITIES = `${SLOT_FACILITIES_BASEURL}facilities/names`
export const apiRolesByFacility = (facilityId) => `${SLOT_FACILITIES_BASEURL}practitioners/roles/facility/${facilityId}`
export const apiPractitionersByRoleAndFacility = (roleId, facilityId) => `${SLOT_FACILITIES_BASEURL}practitioners/names/role/${roleId}/facility/${facilityId}`
export const GET_DOCTORS_SLOT_BY_FACILITY = `${SLOT_SERVICE_API}slots/all/doctors`
export const getRolesForConsultation = (settingId)=>`${SLOT_MANAGEMENT_BASEURL}master-data-service/api/v1/applicable/roles/${settingId}`
export const GET_SLOTS_BY_PRACTITIONER = `${SLOT_SERVICE_API}slot/practitioner`
export const DELETE_SLOT = `${SLOT_SERVICE_API}delete/appointments/slot`
export const CALL_REASONS = `${SLOT_MANAGEMENT_BASEURL}master-data-service/api/v1/` 
//FacilityEmpanelment
const BASE_EMPANEL_FACILITY_URL = process.env.REACT_APP_API_BASE_URL+'facility-service/api/v1/facility/';
export const FACILITY_EMPANELMENT_LIST =`${BASE_EMPANEL_FACILITY_URL}empanelment/`
export const FACILITY_EMPANELMENT_DETAIL_PAGE= process.env.REACT_APP_API_BASE_URL+'facility-service/api/v1/facilities/'
//Doctorempanelment
export const BASE_EMPANEL_URL=process.env.REACT_APP_API_BASE_URL+'practitioner-service/api/v1/practitioners/'
export const DOCTOR_EMPANELMENT_LIST= `${BASE_EMPANEL_URL}empanelment/doctor/`
export const DOCTOR_EMPANELMENT_DETAIL_PAGE= `${BASE_EMPANEL_URL}id/`
export const PRACTITIONER_INFO = `${BASE_EMPANEL_URL}/mobile`
export const AUTH_SERVICE =  process.env.REACT_APP_API_BASE_URL+'auth-service/api/v1/practitioners/loginWithIdentityToken';
export const INVITE_USER = `${BASE_EMPANEL_URL}quick/add`;
//Signup
export const FACILITY_SIGNUP_URL=process.env.REACT_APP_API_BASE_URL+'facility-service/api/v1';
export const PRACTITIONER_SERVICE = process.env.REACT_APP_API_BASE_URL +'practitioner-service/api/v1'
export const MASTER_DATA_SERVICE = process.env.REACT_APP_API_BASE_URL + 'master-data-service/api/v1'
// Consultation
export const GET_JOIN_CONSULTATION = `${process.env.REACT_APP_API_BASE_URL}consultation-service/api/v1/joinConsultation`
export const GET_PAST_CONSULTATION = `${process.env.REACT_APP_API_BASE_URL}consultation-service/api/v1/`
export const GET_CONSULT_ARRAY=`${GET_PAST_CONSULTATION}consultation-type`
export const apiGetSymptoms = (pageNumber, pageSize, limit = 25, skip = 0) => `${process.env.REACT_APP_API_BASE_URL}master-data-service/api/v1/symptom?pageSize=${pageSize}&pageNumber=${pageNumber}&limit=${limit}&skip=${skip}`
export const apiGetInvestigations = (search) => `${process.env.REACT_APP_API_BASE_URL}master-data-service/api/v1/lab-investigation/?search=${search}`
export const apiUpdateCaseSheet = (caseSheetId) => `${process.env.REACT_APP_API_BASE_URL}consultation-service/api/v1/consultation/update-case-sheet/${caseSheetId}`
export const apiGetMedicalRecords = (userId) => `${process.env.REACT_APP_API_BASE_URL}user-locker-service/api/v1/users/${userId}/locker/files`
export const APPOINTMENT_SERVICE = process.env.REACT_APP_API_BASE_URL + 'consultation-service/api/v1'
export const GET_SLOT_LIST_SERVICE = `${SLOT_SERVICE_API}availableTimeSlot`
export const GET_USER_SERVICE = `${SLOT_MANAGEMENT_BASEURL}user-service/api/v1/`
export const POLICY_DETAILS=process.env.REACT_APP_API_BASE_URL + 'policy/api/v1'
export const CLAIMS_CONSULTATION_BASE_URL=`${process.env.REACT_APP_API_BASE_URL}consultation-service/api/v1`
export const UPCOMING_APPOINTMENTS=`${process.env.REACT_APP_API_BASE_URL}consultation-service/api/v1/`
export const VIDEO_SERVICE = process.env.REACT_APP_API_BASE_URL + 'video-service/api/v1/video'
export const STAR_AUTH_SERVICE = process.env.REACT_APP_API_BASE_URL + 'auth-service/api/v1/'
//Receptionist Dashboard
export const DASHBOARD_CALL_COUNT = process.env.REACT_APP_API_BASE_URL + 'dashboard-service/api/v1/'
export const NOTIFICATION_API = process.env.REACT_APP_API_BASE_URL + 'video-service/api/v1/video'
//Upload file count Api

export const FILE_COUNT = process.env.REACT_APP_API_BASE_URL+'chat-service/api/v1'