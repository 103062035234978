import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@material-ui/core";
import styles from "./styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import moment from "moment";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import ic_current from "../../../assets/images/icons/statusCurrent.svg";
import ic_empty from "../../../assets/images/icons/statusEmpty.svg";
import ic_completed from "../../../assets/images/icons/statusCompleted.svg";
import ic_cancelled from "../../../assets/images/ic_cancelled.svg";
import ic_confirmed from "../../../assets/images/ic_confirmed.svg";

interface IDetailProps {
  appointmentJourney: any;
}
const AppointmentTimeline = ({
  appointmentJourney,
}: IDetailProps) => {
  const classes = styles();

  
  var data = appointmentJourney?.filter((x:any)=>x.activeStatus==="COMPLETED");
  var lastStatus =data && data[data?.length - 1]

  const AppointmentConfirmed = lastStatus?.name === "Appointment Confirmed";
  const AppointmentCompleted = lastStatus?.name === "Appointment Completed";
  const AppointmentClosed = lastStatus?.name === "Appointment Closed";
  const AppointmentIncompleted = lastStatus?.name === "Appointment Incomplete";
  const AppointmentCancelled = lastStatus?.name === "Appointment Cancelled";
  const AppointmentRescheduled = lastStatus?.name === "Appointment Reschedule";

  const statusDatas = appointmentJourney?.map((item: any) => ({
    title: item?.name,
    content: item?.message,
    date: item?.timestamps
      ? moment(item?.timestamps).format("ddd, DD MMM (hh:mm a)")
      : "",
    isCancelled: item?.name === "Appointment Cancelled" ? true : false,
    isNext: item?.activeStatus === "COMPLETED" ? false : true,
    isCompleted: item?.activeStatus === "COMPLETED" && item?.name !== "Appointment Cancelled" ? true : false,
  }));


  

  return (
    <Grid
      container
      xs={12}
      style={{ marginTop: "16px" }}
      className={classes.detailsTimeline}
    >
      <Accordion className={classes.detailHeader} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.detailsTitle}
          
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              
            }}
          >
            <Typography variant="h6" className={classes.details}>
              Timeline
            </Typography>

            <Typography
              variant="h6"
              className={
                AppointmentCancelled || AppointmentClosed
                  ? classes.cancelStatusDetail
                  : AppointmentConfirmed || AppointmentCompleted || AppointmentRescheduled
                  ? classes.confirmStatusDetail
                  : classes.statusDetail
              }
            >
              {AppointmentConfirmed ||
                (AppointmentCompleted && (
                  <img
                    src={ic_confirmed}
                    alt="confirmed"
                    style={{ marginRight: "0.5rem" }}
                  />
                ))}
              {AppointmentCancelled
                ? "Appointment Cancelled"
                : AppointmentCompleted
                ? "Appointment Completed"
                : AppointmentConfirmed
                ? "Appointment Confirmed"
                : AppointmentIncompleted
                ? "Appointment Incomplete"
                : AppointmentClosed
                ? "Appointment Closed"
                :AppointmentRescheduled
                ? "Appointment Rescheduled"
                : ""}
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails style={{border: '1px solid #E4E4E4',}}>
          <Timeline>
            {statusDatas?.map((webinar: any, index: number) => {
              return (
                <TimelineItem className={classes.timelineItem}>
                  <TimelineSeparator
                    className={
                      webinar.isCompleted
                        ? classes.completedConnector
                        : classes.emptyConnector
                    }
                  >
                    <img
                      src={
                        webinar?.isCompleted
                          ? ic_completed
                          : webinar?.isCancelled
                          ? ic_cancelled
                          : webinar?.isNext
                          ? ic_current
                          : ic_empty
                      }
                    />
                    {index < statusDatas.length - 1 && <TimelineConnector />}
                  </TimelineSeparator>
                  <div>
                    <TimelineContent style={{ fontWeight: 500 }}>
                      {webinar?.title}
                    </TimelineContent>
                    <TimelineContent style={{ color: "#6C6C6C" }}>
                      {webinar?.date}
                    </TimelineContent>
                    <TimelineContent style={{ fontWeight: 500 }}>
                      <div>{webinar?.content}</div>
                    </TimelineContent>
                  </div>
                </TimelineItem>
              );
            })}
          </Timeline>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default AppointmentTimeline;
