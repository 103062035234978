import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import FullScreenLoader from "./components/common/FullScreenLoader";
import { setAuthCompleted } from "./store/actions";
import { USER_ROLES } from "../src/utils/constants";




const AdminLogin = React.lazy(() => import("./pages/NewAdmin/Login"));
const Logout = React.lazy(() => import("./pages/Logout"));
const AdminOnboarding = React.lazy(() => import("./pages/NewAdmin/Onboarding"));
const AdminSignUp = React.lazy(() => import("./pages/NewAdmin/SignUp"));
const AdminSlider = React.lazy(() => import("./pages/NewAdmin/Slider"));
const GetStarted = React.lazy(() => import("./pages/NewDoctor/GetStarted"));
const Receptionist=React.lazy(() => import("./pages/Receptionist/Dashboard"));
const RolesList = React.lazy(() => import("./pages/NewAdmin/RolesList"));
const ErrorPage = React.lazy(()=> import("./pages/NewAdmin/404Error"))
const FacilityAdmin = React.lazy(() => import("./pages/ManageUser/Admin"));
const FacilityDoctor = React.lazy(() => import("./pages/ManageUser/Doctor"));
const FacilityAhp = React.lazy(() => import("./pages/ManageUser/AHP"));
const FacilityOthers = React.lazy(() => import("./pages/ManageUser/Others"));
const AdminDetail=React.lazy(() => import("./pages/ManageUser/Admin/AdminDetail"));
const AhpDetail=React.lazy(()=> import("./pages/ManageUser/AHP/AhpDetails"))
const OthersDetail=React.lazy(()=> import("./pages/ManageUser/Others/OthersDetail"))
const missedcallDetails = React.lazy(()=>import("./pages/Receptionist/missedcalls"))
const missedcallDetail = React.lazy(()=>import("./pages/FacilityDoctor/missedcalls"))
const Reports = React.lazy(()=>import("./pages/Reports"))

const PhcDashboard = React.lazy(()=>import("./pages/PHC/Dashboard"))
const FinanceDashboard = React.lazy(()=>import("./pages/PHC/Finance"))
const LabTests = React.lazy(()=>import("./pages/PHC/LabTests"))
const PhcStatusRoutes = React.lazy(()=>import("./pages/PHC/common/Listview"));
// ---> Commenting this and will use in next Phase <---//
// const LabTranscriber = React.lazy(() => import("./pages/LabTest"));
// const Empanelmentfacility=React.lazy(()=> import ("./pages/Empanelment/facilityPending"));
// const EmpanelmentDoctor =React.lazy(()=> import ("./pages/Empanelment/DoctorPending"));
// const FacilityDetail=React.lazy(() => import("./pages/Empanelment/FacilityDetail"))
// const DoctorDetail=React.lazy(() => import("./pages/Empanelment/DoctorDetail"))
// const AdminFacilityList = React.lazy(() => import("./pages/NewAdmin/FacilityList"));
// const CreateFacility=React.lazy(() => import("./pages/NewAdmin/CreateFacility"));
// const CreatePassword = React.lazy(() => import("./pages/NewDoctor/CreatePassword"));
//const AdminOtp = React.lazy(() => import("./pages/NewAdmin/Otp"));
// const OperationalReceptionist = React.lazy(() => import("./pages/OperationalReceptionist/Dashboard"));


const AppointmentsManagement = React.lazy(() => import("./pages/AppointmentManagement/Appointment"));
const FollowupRequests = React.lazy(() => import("./pages/AppointmentManagement/FollowupRequest"));
const Consultation = React.lazy(() => import("./pages/AppointmentManagement/Consultation"));
const FacilityPatients = React.lazy(() => import("./pages/ManageUser/Patients"));
const FacilityPatientDetail = React.lazy(() => import("./pages/ManageUser/Patients/PatientDetails"));


// Availability management
const Scheduler = React.lazy(() => import("./pages/SlotManagement"));

// Empanelment
const EmpanelDashboard = React.lazy(() => import("./pages/Empanelment/Dashboard"))
const AdminFacilityDetail = React.lazy(() => import("./pages/NewAdmin/FacilityDetail"));
const AdminAddDoctor = React.lazy(() => import("./pages/NewAdmin/CreateDoctor"));
const AdminDoctors = React.lazy(() => import("./pages/NewAdmin/Doctors"));

//Profiles - Doctor, Admin, Wellness Coach and Facility.
const MyDoctorProfile = React.lazy(()=> import("./pages/NewProfiles/DoctorProfile/myProfile"));
const MyAdminProfile = React.lazy(()=> import("./pages/NewProfiles/AdminProfile/myProfile"));
const ManageUserDoctor = React.lazy(()=> import("./pages/NewProfiles/DoctorProfile/manageUserDoctor"));
const ManageUserAdmin = React.lazy(()=> import("./pages/NewProfiles/AdminProfile/manageUserAdmin"));
const ManageUserReceptionist = React.lazy(()=> import("./pages/NewProfiles/ReceptionistProfile/manageUserReceptionist"));
const ReceptionistProfile = React.lazy(()=> import("./pages/NewProfiles/ReceptionistProfile/myProfile"));
const ManageUserWellness = React.lazy(()=> import("./pages/NewProfiles/WellnessProfile/manageUserAhp"));
const myWellnessProfile = React.lazy(()=> import("./pages/NewProfiles/WellnessProfile/myProfile"));
const AdminProfile = React.lazy(()=> import("./pages/NewProfiles/AdminProfile"));
const WellnessProfile = React.lazy(()=> import("./pages/NewProfiles/WellnessProfile"));
const FacilitySettings = React.lazy(()=> import("./pages/NewProfiles/FacilitySettings"));
const Dashboard = React.lazy(()=> import("./pages/Dashboard"));
const CreateOtherThanDoctor = React.lazy(()=>import("./pages/NewAdmin/CreateOtherThanDoctor"))
const CommonProfile = React.lazy(() => import("./pages/MyProfile"));

const MyOperationalReceptionistProfile = React.lazy(()=> import("./pages/NewProfiles/OperationalReceptionistProfile"));
//claims
const ClaimsDashBoard = React.lazy(()=> import("./pages/Claims/ClaimsDashboard"));
const ClaimsConsultation=React.lazy(()=> import("./pages/Claims/Consultation"));
const EpharmacyList=React.lazy(()=> import("./pages/Claims/Epharmacy"));
const DiagnosticsList=React.lazy(()=> import("./pages/Claims/Diagnostics"));

//consultation
const VideoConsultation=React.lazy(()=> import("./pages/consultation/ConsultationRender"));
//PHC
const Invoices =React.lazy(()=> import("./pages/PHC/History/invoices"))
const ApprovedEdit =React.lazy(()=> import("./pages/PHC/History/invoices/editPage"))
const FinanceDetail =React.lazy(()=> import("./pages/PHC/Finance/detail"))

export default function Routes({ isAuthenticated }) {
  const { userRole, isLoggedUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();
  const DashboardRedirect = location.pathname === '/';

  useEffect(() => {
    if (isAuthenticated && location?.pathname == "/clinic/list") {
      dispatch(setAuthCompleted(true));
    }
  }, [isAuthenticated, location.pathname]);

  const restrictedRoutes = [
  //<-- Commenting this and will use in next Phase -->
    // {
    //   path: "/labTranscriber",
    //   component: LabTranscriber,
    //   roles: [USER_ROLES.transcription_agent],
    // },
      // {
    //   path: "/Empanelment/facilityEmpanelment",
    //   component: Empanelmentfacility,
    //   roles: [USER_ROLES.empanelment_admin],
    // },
    // {
    //   path: "/Empanelment/DoctorEmpanelment",
    //   component: EmpanelmentDoctor,
    //   roles: [USER_ROLES.empanelment_admin],
    // },
    // {
    //   path:"/Empanelment/facilityEmpanelment/FacilityDetail",
    //   component:FacilityDetail,
    //   roles:[USER_ROLES.empanelment_admin],
    // },
    // {
    //   path:"/Empanelment/DoctorEmpanelment/DoctorDetail",
    //   component:DoctorDetail,
    //   roles:[USER_ROLES.empanelment_admin],
    // },
      // { path: "/createfacility", component: CreateFacility, roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin,USER_ROLES.doctor,USER_ROLES.field_agent] },
    // {path:'/onboarding' ,component:AdminOnboarding ,roles:['All']},
    // {path:'/admin/reset-password' ,component:AdminResetPassword ,roles:['All']},
    // {path:'/admin/otp' ,component:AdminOtp ,roles:['All']},
    // { path: "/admin/facility-list", component: AdminFacilityList, exact : true,roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin,USER_ROLES.doctor,USER_ROLES.field_agent,USER_ROLES.telemedicine_admin,USER_ROLES.operations_admin,USER_ROLES.receptionist, USER_ROLES.facility_receptionist] },
    // { path: "/admin/reset-password", component: AdminResetPassword, roles: ["All"] },
     // {path: "/Empanelment/facilityEmpanelment/createfacility",component:CreateFacility,roles:[USER_ROLES.empanelment_admin]},
    // {path: "/Empanelment/DoctorEmpanelment/:facilityid",component:AdminAddDoctor,roles:[USER_ROLES.empanelment_admin]},
    // { path: "/OperationalReceptionist/dashboard", component: OperationalReceptionist, roles: [USER_ROLES.opd_ops] },

    /* { path: "/login", component: AdminLogin, roles: ["All"] }, */
    {path: "/ManageUsers/Others/create/:userType",component:CreateOtherThanDoctor,roles:[USER_ROLES.facility_admin]},
    {path: "/ManageUsers/Doctor/create/:userType",component:AdminAddDoctor,roles:[USER_ROLES.facility_admin]},
    { path: "/logout", component: Logout, roles: ["All"], },
    {
      path: "/ManageUsers/Admin",
      component: FacilityAdmin,
      exact: true,
      roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin],
    },
    {
      path: "/ManageUsers/Doctor",
      component: FacilityDoctor,
      exact: true,
      roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin],
    }, 
    {
      path: "/ManageUsers/AHP",
      component: FacilityAhp,
      exact: true,
      roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin],
    }, 
    {
      path: "/ManageUsers/Others",
      component: FacilityOthers,
      exact: true,
      roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin],
    },
    {
      path: "/ManageUsers/Patients",
      component: FacilityPatients,
      exact: true,
      roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin],
    },
    {
      path: "/ManageUsers/Patients/Detail",
      component: FacilityPatientDetail,
      exact: true,
      roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin],
    },
    {
      path: "/ManageUsers/AdminDetail/:id?",
      component: AdminDetail,
      exact: true,
      roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin],
    },  
    {
      path: "/ManageUsers/AhpDetail/:id?",
      component: AhpDetail,
      exact: true,
      roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin],
    }, 
    {
      path: "/ManageUsers/OthersDetail/:id?",
      component: OthersDetail,
      exact: true,
      roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin],
    }, 
    {
      path: "/Appointments",
      component: AppointmentsManagement,
      roles: [USER_ROLES.facility_admin,USER_ROLES.doctor,USER_ROLES.receptionist,USER_ROLES.opd_ops,USER_ROLES.facility_receptionist],
    },
    {
      path: "/Reports",
      component: Reports,
      roles: [USER_ROLES.facility_admin],
    },
    {
      path: "/Appointments/consultation",
      component: Consultation,
      roles: ["All"],
    },
    {
      path: "/Followup_requests",
      component: FollowupRequests,
      roles: ["All"],
    },
    {
      path:'/chat-consulation',
      component : Consultation,
      roles : [USER_ROLES.doctor]
    },
    { path: "/Receptionist/dashboard", component: Receptionist, roles: [USER_ROLES.receptionist,USER_ROLES.facility_receptionist] },
    { path: "/Empanelment/dashboard", component: EmpanelDashboard, roles: [USER_ROLES.empanelment_admin] },
    {path:'/signup' ,component:AdminSignUp ,roles:['All']},
    { path: "/admin/roles-list", component: RolesList, roles: ["All"] },
    { path: "/admin/invite", component: GetStarted, roles: ["All"] },
    { path: "/admin/facility-detail/:facilityid?", component: AdminFacilityDetail, roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin] },

    { path: "/admin/add-doctor/:facilityid", component: AdminAddDoctor, roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin,USER_ROLES.doctor,USER_ROLES.field_agent]},

    { path: "/admin/doctors-list", component: AdminDoctors, roles: ["All"] },

    // { path: "/claims/dashboard", component: ClaimsDashBoard, roles: [USER_ROLES.claims_admin,USER_ROLES.claims_processor] },

    {
      path: "/appointment-scheduler",
      component: Scheduler,
      roles: [USER_ROLES.facility_admin,USER_ROLES.doctor,USER_ROLES.doctor,USER_ROLES.receptionist,USER_ROLES.super_admin,USER_ROLES.opd_ops, USER_ROLES.facility_receptionist],
    },
    {path :"/MyProfile",component : CommonProfile,exact : true,roles:["All"]},
    { path: "/Dashboard", component: Dashboard,exact : true, roles: ["All"] },
    { path: "/Empanelment/index", component: EmpanelDashboard, roles: ["All"] },
    { path: "/MyProfile/doctor", component: MyDoctorProfile, roles: [USER_ROLES.doctor] },
    { path: "/newprofile/operational-recepptionist", component: MyOperationalReceptionistProfile, roles: [USER_ROLES.opd_ops] },
    { path: "/MyProfile/admin", component: MyAdminProfile, roles: [USER_ROLES.facility_admin, USER_ROLES?.phc_operation_admin,USER_ROLES?.phc_operation,USER_ROLES?.phc_finance_admin,USER_ROLES?.lab_vendor] },
    { path: "/MyProfile/receptionist", component: ReceptionistProfile, roles: [USER_ROLES.receptionist, USER_ROLES.facility_receptionist] },
    { path: "/ManageUsers/Doctor/profile:doctorId?", component: ManageUserDoctor, roles: [USER_ROLES.facility_admin] },
    { path: "/ManageUsers/Admin/profile:adminId?", component: ManageUserAdmin, roles: [USER_ROLES.facility_admin] },
    { path: "/ManageUsers/Others/profile:receptionistId?", component: ManageUserReceptionist, roles: [USER_ROLES.facility_admin] },
    { path: "/ManageUsers/AHP/profile:wellnessId?", component: ManageUserWellness, roles: [USER_ROLES.facility_admin] },
    { path: "/newprofile/wellness", component:myWellnessProfile, roles: ["All"] },
    { path: "/newprofile/admin", component: AdminProfile, roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin,USER_ROLES.field_agent]},
    { path: "/newprofile/wellness", component: WellnessProfile, roles: ["All"] },
    { path: `/newprofile/facility`, component: FacilitySettings, roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin] },
    { path: "/Admin/Slider", component: AdminSlider, roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin] },
    // { path: "/claims/consultation", component: ClaimsConsultation, roles: [USER_ROLES.claims_admin,USER_ROLES.claims_processor] },
    // { path: "/claims/epharmacylist", component: EpharmacyList, roles: [USER_ROLES.claims_admin,USER_ROLES.claims_processor] },
    // { path: "/claims/diagnostics", component: DiagnosticsList, roles: [USER_ROLES.claims_admin,USER_ROLES.claims_processor] },
    { path: "/Appointments/room:urlname?", component: VideoConsultation, roles: [USER_ROLES.doctor,USER_ROLES.receptionist] },
    { path: "/InstantCall/room:urlname?", component: VideoConsultation, roles: [USER_ROLES.doctor,USER_ROLES.receptionist] },
    { path:'/404/error', component:ErrorPage, roles: ["All"] },
    { path: "/Dashboard/missedCalls", component: missedcallDetails, roles: ["All"] },
    { path: "/Dashboard/missedCall", component: missedcallDetail, roles: ["All"] },
    { path: "/Invoices/OperationAdmin", component: Invoices, roles: ["All"] },
  
    { path: "/Invoices/OperationAdmin/edit", component: ApprovedEdit, roles: ["All"] },
    { path: "/Dashboard", component: PhcDashboard, roles: ["All"] },
    { path: "/Dashboard", component: FinanceDashboard, roles: [USER_ROLES?.phc_finance_admin] },
    { path: "/Dashboard/detail", component: FinanceDetail, roles: [USER_ROLES?.phc_finance_admin] },
    { path: "/Invoices/OperationAdmin/detail", component: FinanceDetail, roles: [USER_ROLES?.phc_operation_admin] },
    { path: "/LabTests", component: LabTests, roles: [USER_ROLES?.lab_vendor] },
    {path : "/Upcoming",component: PhcStatusRoutes, roles: [USER_ROLES?.phc_operation,USER_ROLES?.phc_operation_admin,USER_ROLES?.lab_vendor]},
    {path : "/Inprogress",component: PhcStatusRoutes, roles: [USER_ROLES?.phc_operation,USER_ROLES?.phc_operation_admin,USER_ROLES?.lab_vendor]},
    {path : "/history",component: PhcStatusRoutes, roles: [USER_ROLES?.phc_operation,USER_ROLES?.phc_operation_admin,USER_ROLES?.lab_vendor]},
    {path : "/Requests",component: PhcStatusRoutes, roles: [USER_ROLES?.lab_vendor]}
  ];
  

  let routes = (
    <Switch>
      <Route path='/login' exact = {true} component={AdminLogin} />
      <Route path="/onboarding" exact={true} component ={AdminOnboarding} />
      <Route path="/signup" exact={true} component ={AdminSignUp} />
      { !isLoggedUser  && <Redirect path = '/'  to={location.pathname.includes('/onboarding') ? '/onboarding' : '/login' } />}
    </Switch>
  );
  if (isLoggedUser) {
    routes = (
       <Switch>
        {restrictedRoutes
          .filter((route) => {
            if(route.path === '/chat-consulation' && !location.search.includes('roomID')) return false
            if (route.roles.includes("All")) return true;
            if (route.roles.includes(userRole)) return true;
          })
          .map((route) => {
            return (
              <Route path={route.path} component={route.component} exact />
            );
          })}
           <Redirect from="/" to={isAuthenticated ? userRole===USER_ROLES?.phc_lab_walkin ? "/Lab/Upcoming" : (DashboardRedirect || location.pathname === '/login') ? '/Dashboard' : "/404/error" : "/login"} />
      </Switch>
    );
  }

  return <Suspense fallback={<FullScreenLoader />}>{routes}</Suspense>;
}