import { isValidPhoneNumber as validateMobileNumber} from "react-phone-number-input/mobile";
import { isValidPhoneNumber as validatePhoneNumber} from "react-phone-number-input";
import moment from "moment"
import * as yup from "yup";

export const EMAIL_REGEX = /^[a-zA-Z0-9._]{3,}@[a-zA-Z]+\.[a-zA-Z]{2,3}$/g;
export const PHONE_NUMBER_REGEX = /^(\+91?|[0]?)?[6789]\d{9}$/gm;
export const MOBILE_NUMBER_REGEX = /^[0]?[6789]\d{9}$/gm;
const PASSWORD_REGEX = /^(?=.*\d)(?!.* )(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,15}$/
const NAME_REGEX = /^[a-zA-Z ]*$/
const DEPARTMENT_REGEX = /^[a-zA-Z,&() -]*$/
const PIN_CODE_REGEX = /^[1-9][0-9]*$/
const CLINIC_NAME_REGEX = /^([a-zA-Z]+\s)*[a-zA-Z]+$/
const CAREPROGRAM_NAME_REGEX = /^[a-zA-Z_ \-]*$/
const PARAMETER_NAME = /^[a-zA-Z0-9-/() ]*$/
const CODE= /^[a-zA-Z0-9- ]*$/
const DIGIT= /^-?\d*?\d*$/
export const isValidPhoneInput = (number)=>{
        return process.env.REACT_APP_PHONE_NUMBER_VALIDATOR_TYPE === "mobile"
        ? validateMobileNumber(number)
        : validatePhoneNumber(number);
}

export const validations = {
    firstname: yup.string().matches(NAME_REGEX, 'Please enter a valid name').min(2, 'Name is too short').max(60, 'Name is too long'),
    firstnameRequired: yup.string().required("Required field cannot be left blank").matches(NAME_REGEX, 'Please enter a valid name').min(2, 'Name is too short').max(60, 'Name is too long').trim(),
    lastname: yup.string().matches(NAME_REGEX, 'Please enter a valid name').min(2, 'Name is too short').max(60, 'Name is too long'),
    lastnameRequired: yup.string().required("Required field cannot be left blank").matches(NAME_REGEX, 'Please enter a valid name').min(2, 'Name is too short').max(60, 'Name is too long').trim(),
    phone: yup.string().test('phone-validation', 'Please enter valid mobile number.', (value) => value && isValidPhoneInput(value) || !value),
    phoneRequired: yup.string().required("Required field cannot be left blank").matches(PHONE_NUMBER_REGEX, 'Please enter valid mobile number.').test('phone-validation', 'Please enter valid mobile number.', (value) => value && isValidPhoneInput(value)),
    mobileRequired: yup.string().required("Required field cannot be left blank").matches(MOBILE_NUMBER_REGEX, 'Please enter valid mobile number.'),
    password: yup.string().required("Required field cannot be left blank").matches(PASSWORD_REGEX, "Password should be minimum 8 characters, maximum 15 characters, no spaces, at least one uppercase, one number and one special character (eg : Abcd@123)"),
    email: yup.string().email("Please enter a valid email address"),
    address: yup.string().min(1, 'Required field cannot be left blank'), 
    emailRequired: yup.string().required("Required field cannot be left blank").max(256, 'Email is too long').email("Please enter a valid email address").matches(EMAIL_REGEX, 'Please enter a valid email address').trim(),
    pincode: yup.string().min(4, 'Please enter a valid pincode').max(16, 'Please enter a valid pincode'),
    pincodeRequired: yup.string().required("Required field cannot be left blank").min(6, 'Please enter a valid pincode').max(6, 'Please enter a valid pincode').matches(PIN_CODE_REGEX, 'Please enter a valid pincode'),
    height: yup.number().typeError('Please enter valid height'),
    weight: yup.number().typeError('Please enter valid weight'),    
    commonRequired: yup.string().trim().required("Required field cannot be left blank").nullable(),
    genderRequired: yup.string().required("Required field cannot be left blank").oneOf(['male', 'female', 'other'], "Required field cannot be left blank"),
    dobRequired: yup.mixed().required("Required field cannot be left blank").test('dob-validation', 'Please select valid date of birth', (value) => value && moment(value).isValid() && moment(value).isSameOrBefore(moment(), 'D')),
    doqRequired: yup.mixed().required("Required field cannot be left blank").test('doq-validation', 'Please select valid date of qualification', (value) => value && moment(value).isValid() && moment(value).isSameOrBefore(moment(), 'D')),
    timeRequired: yup.mixed().required("Required field cannot be left blank").test('dob-validation', 'Please select valid time', (value) => value && moment(value).isValid()),
    freeTextInput: yup.string().required("Required field cannot be left blank").min(2, 'Too Short').max(250, 'Too Long'),
    validPositiveNumber: yup.number().typeError('Required field cannot be left blank').required("Required field cannot be left blank").positive('Please enter a valid number'),
    careProgramName: yup.string().trim().required("Required field cannot be left blank").min(3, "Too short").max(80, "Wellness program name is too long").matches(CAREPROGRAM_NAME_REGEX, 'Enter valid name. Special characters not allowed'),
    boolean: yup.boolean(),
    experienceRequired: yup.number().integer().required("Required field cannot be left blank").typeError('Please enter valid experience').min(0, 'Please enter valid experience').max(99, 'Please enter valid experience'),
    bio: yup.string().trim().required("Required field cannot be left blank").min(2, 'Too Short').max(200, 'Bio is too long'),
    primaryspeciality: yup.string().trim().required("Required field cannot be left blank").max(40, 'primaryspecialty is too long').matches(DEPARTMENT_REGEX,"Please enter a valid primary specialty"),
    parameterName: yup.string().trim().required("Required field cannot be left blank").nullable().max(60, 'Parameter name is too long').matches(PARAMETER_NAME, "Please enter a valid parameter name"),
    code: yup.string().trim().required("Required field cannot be left blank").nullable().max(15, 'Code is too long').min(3,'Code is too short').matches(CODE, "Please enter a valid code"),
    units: yup.array().min(1, 'Required field cannot be left blank').max(10, 'you can select maximum 10 units'),
    uploadFormat: yup.array().required("Required field cannot be left blank"),
    aadharNumber:yup.number().typeError('Required field cannot be left blank').required("Required field cannot be left blank"),
    ifscCode: yup.string().required("Required field cannot be left blank").nullable().trim().matches(/^[A-Z]{4}0[A-Z0-9]{6}$/,"Enter Valid IFSC Code"),
    panNumber : yup.string().required("Required field cannot be left blank").nullable().trim().matches(/([A-Z]){5}([0-9]){4}([A-Z]){1}$/,"Enter Valid PanNumber"),
    websiteName:yup.string().nullable().matches(/((?:(http|ftp|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gm,"Enter Valid Website"),
    bankNumber: yup.string().required("Required field cannot be left blank").nullable().trim().matches(/^\d{9,18}$/,"Enter Valid AccountNumber"),
    inConsulationFee : yup.number().transform((value) => (isNaN(value) || value === null || value === undefined) ? 0 : value).min(1,"Minimum value should be 1").max(9999,"Maximum value should be 9999"),
    virtualFee : yup.number().transform((value) => (isNaN(value) || value === null || value === undefined) ? 0 : value).min(1,"Minimum value should be 1").max(9999,"Maximum value should be 9999"),
    facilityName: yup.string().required("Required field cannot be left blank").matches(/^[a-zA-Z0-9 ,.""-''&()\s]*$/, 'Please enter a valid name').min(2, 'Name is too short').max(256, 'Name is too long'),
    establishmentNumber: yup.string().required("Required field cannot be left blank").matches(/^[a-zA-Z0-9 ]*$/, 'Please enter a valid name').min(4, 'Number is too short').max(25, 'Number is too long'),
    city: yup.string().required("Select City").nullable(),
    state: yup.string().required("Select State").nullable(),
    country: yup.string().required("Select Country").nullable(),
    specialisation: yup.string().required("Select facility type").nullable(),
    emailcheck:yup.string().required("Required field cannot be left blank").email("Please enter a valid email address"),
    validWeightRequired: yup.number().integer().nullable().transform((_, val) => (val !== "" ? Number(val) : null)).moreThan(2,"Minimum value should be minimum 3").lessThan(401,"Maximum value should be maximum 400"),
    validHeightRequired: yup.number().integer().nullable().transform((_, val) => (val !== "" ? Number(val) : null)).moreThan(19,"Minimum value should be minimum 20").lessThan(301,"Maximum value should be maximum 300"),
    validTempRequired: yup.number().integer().nullable().transform((_, val) => (val !== "" ? Number(val) : null)).moreThan(33,"Minimum value should be minimum 34").lessThan(44,"Maximum value should be maximum 43")
}