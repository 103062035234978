import { CLAIMS_CONSULTATION_BASE_URL, FILE_COUNT } from "../../config/url.config";
import Services from "../services";

const instance = new Services().getServiceInstance();

  export const bookConsultationInstant = async (key: string, payload: any) => {
    const {data} = await instance.post(`${CLAIMS_CONSULTATION_BASE_URL}/bookConsultationForInstantChat`, payload)
    return data;
}

export const InstantUpdateDoctor = async (key: string, payload: any) => {
  const {data} = await instance.post(`${CLAIMS_CONSULTATION_BASE_URL}/updateDoctor`, payload)
  return data;
} 

export const getOnlineUserList = async(isList: string) => {
  const {data} = await instance.get(`${FILE_COUNT}/getLoginStatus?userId=${isList}`)
  return data;
}